import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  float8: any;
  geography: { type: 'Point'; coordinates: [number, number] };
  geometry: any;
  jsonb: any;
  numeric: any;
  timestamptz: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type LabUserLogoutOutput = {
  __typename?: 'LabUserLogoutOutput';
  result: Scalars['String'];
};

export type PingOutput = {
  __typename?: 'PingOutput';
  pong: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type AvailableLaboratoryTests_Test_Args = {
  point?: InputMaybe<Scalars['geography']>;
};

export type Available_Laboratory_Args = {
  _limit?: InputMaybe<Scalars['Int']>;
  _offset?: InputMaybe<Scalars['Int']>;
  point?: InputMaybe<Scalars['geography']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

export type Distance_Laboratory_Collection_Point_Args = {
  location?: InputMaybe<Scalars['geography']>;
};

/** columns and relationships of "file" */
export type File = {
  __typename?: 'file';
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  orderDocuments: Array<Order_Document>;
  /** An aggregate relationship */
  orderDocuments_aggregate: Order_Document_Aggregate;
  /** An array relationship */
  orderTestResults: Array<Order_Test_Result>;
  /** An array relationship */
  trainings: Array<Training>;
  /** An aggregate relationship */
  trainings_aggregate: Training_Aggregate;
};


/** columns and relationships of "file" */
export type FileOrderDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Order_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Document_Order_By>>;
  where?: InputMaybe<Order_Document_Bool_Exp>;
};


/** columns and relationships of "file" */
export type FileOrderDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Document_Order_By>>;
  where?: InputMaybe<Order_Document_Bool_Exp>;
};


/** columns and relationships of "file" */
export type FileOrderTestResultsArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Result_Order_By>>;
  where?: InputMaybe<Order_Test_Result_Bool_Exp>;
};


/** columns and relationships of "file" */
export type FileTrainingsArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};


/** columns and relationships of "file" */
export type FileTrainings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "file". All fields are combined with a logical 'AND'. */
export type File_Bool_Exp = {
  _and?: InputMaybe<Array<File_Bool_Exp>>;
  _not?: InputMaybe<File_Bool_Exp>;
  _or?: InputMaybe<Array<File_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  orderDocuments?: InputMaybe<Order_Document_Bool_Exp>;
  orderDocuments_aggregate?: InputMaybe<Order_Document_Aggregate_Bool_Exp>;
  orderTestResults?: InputMaybe<Order_Test_Result_Bool_Exp>;
  trainings?: InputMaybe<Training_Bool_Exp>;
  trainings_aggregate?: InputMaybe<Training_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "file" */
export enum File_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilePkey = 'file_pkey'
}

/** input type for inserting data into table "file" */
export type File_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  orderTestResults?: InputMaybe<Order_Test_Result_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "file" */
export type File_Mutation_Response = {
  __typename?: 'file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<File>;
};

/** input type for inserting object relation for remote table "file" */
export type File_Obj_Rel_Insert_Input = {
  data: File_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<File_On_Conflict>;
};

/** on_conflict condition type for table "file" */
export type File_On_Conflict = {
  constraint: File_Constraint;
  update_columns?: Array<File_Update_Column>;
  where?: InputMaybe<File_Bool_Exp>;
};

/** Ordering options when selecting data from "file". */
export type File_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  orderDocuments_aggregate?: InputMaybe<Order_Document_Aggregate_Order_By>;
  orderTestResults_aggregate?: InputMaybe<Order_Test_Result_Aggregate_Order_By>;
  trainings_aggregate?: InputMaybe<Training_Aggregate_Order_By>;
};

/** select columns of table "file" */
export enum File_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "file" */
export type File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type File_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** placeholder for update columns of table "file" (current role has no relevant permissions) */
export enum File_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "gender" */
export type Gender = {
  __typename?: 'gender';
  gender: Scalars['String'];
};

/** Boolean expression to filter rows from the table "gender". All fields are combined with a logical 'AND'. */
export type Gender_Bool_Exp = {
  _and?: InputMaybe<Array<Gender_Bool_Exp>>;
  _not?: InputMaybe<Gender_Bool_Exp>;
  _or?: InputMaybe<Array<Gender_Bool_Exp>>;
  gender?: InputMaybe<String_Comparison_Exp>;
};

export enum Gender_Enum {
  Female = 'female',
  Male = 'male'
}

/** Boolean expression to compare columns of type "gender_enum". All fields are combined with logical 'AND'. */
export type Gender_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Gender_Enum>;
  _in?: InputMaybe<Array<Gender_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Gender_Enum>;
  _nin?: InputMaybe<Array<Gender_Enum>>;
};

/** Ordering options when selecting data from "gender". */
export type Gender_Order_By = {
  gender?: InputMaybe<Order_By>;
};

/** select columns of table "gender" */
export enum Gender_Select_Column {
  /** column name */
  Gender = 'gender'
}

/** Streaming cursor of the table "gender" */
export type Gender_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gender_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gender_Stream_Cursor_Value_Input = {
  gender?: InputMaybe<Scalars['String']>;
};

export type Geography_Cast_Exp = {
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: InputMaybe<Geography_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geography']>;
  _gt?: InputMaybe<Scalars['geography']>;
  _gte?: InputMaybe<Scalars['geography']>;
  _in?: InputMaybe<Array<Scalars['geography']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geography']>;
  _lte?: InputMaybe<Scalars['geography']>;
  _neq?: InputMaybe<Scalars['geography']>;
  _nin?: InputMaybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars['geography']>;
};

export type Geometry_Cast_Exp = {
  geography?: InputMaybe<Geography_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: InputMaybe<Geometry_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geometry']>;
  _gt?: InputMaybe<Scalars['geometry']>;
  _gte?: InputMaybe<Scalars['geometry']>;
  _in?: InputMaybe<Array<Scalars['geometry']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geometry']>;
  _lte?: InputMaybe<Scalars['geometry']>;
  _neq?: InputMaybe<Scalars['geometry']>;
  _nin?: InputMaybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars['geometry']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "lab_user" */
export type Lab_User = {
  __typename?: 'lab_user';
  email: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "lab_user". All fields are combined with a logical 'AND'. */
export type Lab_User_Bool_Exp = {
  _and?: InputMaybe<Array<Lab_User_Bool_Exp>>;
  _not?: InputMaybe<Lab_User_Bool_Exp>;
  _or?: InputMaybe<Array<Lab_User_Bool_Exp>>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "lab_user". */
export type Lab_User_Order_By = {
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** select columns of table "lab_user" */
export enum Lab_User_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "lab_user" */
export type Lab_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lab_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lab_User_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "laboratory" */
export type Laboratory = {
  __typename?: 'laboratory';
  /** An array relationship */
  collectionPoints: Array<Laboratory_Collection_Point>;
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  /** An array relationship */
  laboratoryTests: Array<Laboratory_Test>;
  /** An aggregate relationship */
  laboratoryTests_aggregate: Laboratory_Test_Aggregate;
  /** An object relationship */
  logoFile?: Maybe<File>;
  name: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
};


/** columns and relationships of "laboratory" */
export type LaboratoryCollectionPointsArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Collection_Point_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Collection_Point_Order_By>>;
  where?: InputMaybe<Laboratory_Collection_Point_Bool_Exp>;
};


/** columns and relationships of "laboratory" */
export type LaboratoryLaboratoryTestsArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


/** columns and relationships of "laboratory" */
export type LaboratoryLaboratoryTests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};

/** order by aggregate values of table "laboratory" */
export type Laboratory_Aggregate_Order_By = {
  avg?: InputMaybe<Laboratory_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Laboratory_Max_Order_By>;
  min?: InputMaybe<Laboratory_Min_Order_By>;
  stddev?: InputMaybe<Laboratory_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Laboratory_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Laboratory_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Laboratory_Sum_Order_By>;
  var_pop?: InputMaybe<Laboratory_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Laboratory_Var_Samp_Order_By>;
  variance?: InputMaybe<Laboratory_Variance_Order_By>;
};

/** order by avg() on columns of table "laboratory" */
export type Laboratory_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "laboratory". All fields are combined with a logical 'AND'. */
export type Laboratory_Bool_Exp = {
  _and?: InputMaybe<Array<Laboratory_Bool_Exp>>;
  _not?: InputMaybe<Laboratory_Bool_Exp>;
  _or?: InputMaybe<Array<Laboratory_Bool_Exp>>;
  collectionPoints?: InputMaybe<Laboratory_Collection_Point_Bool_Exp>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  laboratoryTests?: InputMaybe<Laboratory_Test_Bool_Exp>;
  laboratoryTests_aggregate?: InputMaybe<Laboratory_Test_Aggregate_Bool_Exp>;
  logoFile?: InputMaybe<File_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  shortName?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "laboratory_collection_point" */
export type Laboratory_Collection_Point = {
  __typename?: 'laboratory_collection_point';
  address: Scalars['String'];
  city: Scalars['String'];
  /** A computed field, executes function "laboratory_collection_point_distance" */
  distance?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  /** An object relationship */
  laboratory: Laboratory;
  laboratoryId: Scalars['Int'];
  location: Scalars['geography'];
  name: Scalars['String'];
  openingHours: Scalars['jsonb'];
  /** An array relationship */
  orderTests: Array<Order_Test>;
  postalCode: Scalars['String'];
};


/** columns and relationships of "laboratory_collection_point" */
export type Laboratory_Collection_PointDistanceArgs = {
  args: Distance_Laboratory_Collection_Point_Args;
};


/** columns and relationships of "laboratory_collection_point" */
export type Laboratory_Collection_PointOpeningHoursArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "laboratory_collection_point" */
export type Laboratory_Collection_PointOrderTestsArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Order_By>>;
  where?: InputMaybe<Order_Test_Bool_Exp>;
};

/** order by aggregate values of table "laboratory_collection_point" */
export type Laboratory_Collection_Point_Aggregate_Order_By = {
  avg?: InputMaybe<Laboratory_Collection_Point_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Laboratory_Collection_Point_Max_Order_By>;
  min?: InputMaybe<Laboratory_Collection_Point_Min_Order_By>;
  stddev?: InputMaybe<Laboratory_Collection_Point_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Laboratory_Collection_Point_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Laboratory_Collection_Point_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Laboratory_Collection_Point_Sum_Order_By>;
  var_pop?: InputMaybe<Laboratory_Collection_Point_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Laboratory_Collection_Point_Var_Samp_Order_By>;
  variance?: InputMaybe<Laboratory_Collection_Point_Variance_Order_By>;
};

/** order by avg() on columns of table "laboratory_collection_point" */
export type Laboratory_Collection_Point_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "laboratory_collection_point". All fields are combined with a logical 'AND'. */
export type Laboratory_Collection_Point_Bool_Exp = {
  _and?: InputMaybe<Array<Laboratory_Collection_Point_Bool_Exp>>;
  _not?: InputMaybe<Laboratory_Collection_Point_Bool_Exp>;
  _or?: InputMaybe<Array<Laboratory_Collection_Point_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  laboratory?: InputMaybe<Laboratory_Bool_Exp>;
  laboratoryId?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<Geography_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  openingHours?: InputMaybe<Jsonb_Comparison_Exp>;
  orderTests?: InputMaybe<Order_Test_Bool_Exp>;
  postalCode?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "laboratory_collection_point" */
export type Laboratory_Collection_Point_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "laboratory_collection_point" */
export type Laboratory_Collection_Point_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "laboratory_collection_point". */
export type Laboratory_Collection_Point_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laboratory?: InputMaybe<Laboratory_Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  openingHours?: InputMaybe<Order_By>;
  orderTests_aggregate?: InputMaybe<Order_Test_Aggregate_Order_By>;
  postalCode?: InputMaybe<Order_By>;
};

/** select columns of table "laboratory_collection_point" */
export enum Laboratory_Collection_Point_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  Id = 'id',
  /** column name */
  LaboratoryId = 'laboratoryId',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  OpeningHours = 'openingHours',
  /** column name */
  PostalCode = 'postalCode'
}

/** order by stddev() on columns of table "laboratory_collection_point" */
export type Laboratory_Collection_Point_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "laboratory_collection_point" */
export type Laboratory_Collection_Point_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "laboratory_collection_point" */
export type Laboratory_Collection_Point_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "laboratory_collection_point" */
export type Laboratory_Collection_Point_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Laboratory_Collection_Point_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Laboratory_Collection_Point_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  laboratoryId?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['geography']>;
  name?: InputMaybe<Scalars['String']>;
  openingHours?: InputMaybe<Scalars['jsonb']>;
  postalCode?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "laboratory_collection_point" */
export type Laboratory_Collection_Point_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "laboratory_collection_point" */
export type Laboratory_Collection_Point_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "laboratory_collection_point" */
export type Laboratory_Collection_Point_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "laboratory_collection_point" */
export type Laboratory_Collection_Point_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
};

/** order by max() on columns of table "laboratory" */
export type Laboratory_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  shortName?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "laboratory" */
export type Laboratory_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  shortName?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "laboratory". */
export type Laboratory_Order_By = {
  collectionPoints_aggregate?: InputMaybe<Laboratory_Collection_Point_Aggregate_Order_By>;
  enabled?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laboratoryTests_aggregate?: InputMaybe<Laboratory_Test_Aggregate_Order_By>;
  logoFile?: InputMaybe<File_Order_By>;
  name?: InputMaybe<Order_By>;
  shortName?: InputMaybe<Order_By>;
};

/** select columns of table "laboratory" */
export enum Laboratory_Select_Column {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ShortName = 'shortName'
}

/** order by stddev() on columns of table "laboratory" */
export type Laboratory_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "laboratory" */
export type Laboratory_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "laboratory" */
export type Laboratory_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "laboratory" */
export type Laboratory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Laboratory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Laboratory_Stream_Cursor_Value_Input = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  shortName?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "laboratory" */
export type Laboratory_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "laboratory_test" */
export type Laboratory_Test = {
  __typename?: 'laboratory_test';
  id: Scalars['Int'];
  /** An object relationship */
  laboratory: Laboratory;
  laboratoryId: Scalars['Int'];
  price: Scalars['numeric'];
  /** An object relationship */
  test: Test;
  testId: Scalars['Int'];
  /** A computed field, executes function "laboratory_test_waiting_time_i18n" */
  waitingTime?: Maybe<Scalars['String']>;
};

/** aggregated selection of "laboratory_test" */
export type Laboratory_Test_Aggregate = {
  __typename?: 'laboratory_test_aggregate';
  aggregate?: Maybe<Laboratory_Test_Aggregate_Fields>;
  nodes: Array<Laboratory_Test>;
};

export type Laboratory_Test_Aggregate_Bool_Exp = {
  count?: InputMaybe<Laboratory_Test_Aggregate_Bool_Exp_Count>;
};

export type Laboratory_Test_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Laboratory_Test_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "laboratory_test" */
export type Laboratory_Test_Aggregate_Fields = {
  __typename?: 'laboratory_test_aggregate_fields';
  avg?: Maybe<Laboratory_Test_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Laboratory_Test_Max_Fields>;
  min?: Maybe<Laboratory_Test_Min_Fields>;
  stddev?: Maybe<Laboratory_Test_Stddev_Fields>;
  stddev_pop?: Maybe<Laboratory_Test_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Laboratory_Test_Stddev_Samp_Fields>;
  sum?: Maybe<Laboratory_Test_Sum_Fields>;
  var_pop?: Maybe<Laboratory_Test_Var_Pop_Fields>;
  var_samp?: Maybe<Laboratory_Test_Var_Samp_Fields>;
  variance?: Maybe<Laboratory_Test_Variance_Fields>;
};


/** aggregate fields of "laboratory_test" */
export type Laboratory_Test_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "laboratory_test" */
export type Laboratory_Test_Aggregate_Order_By = {
  avg?: InputMaybe<Laboratory_Test_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Laboratory_Test_Max_Order_By>;
  min?: InputMaybe<Laboratory_Test_Min_Order_By>;
  stddev?: InputMaybe<Laboratory_Test_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Laboratory_Test_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Laboratory_Test_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Laboratory_Test_Sum_Order_By>;
  var_pop?: InputMaybe<Laboratory_Test_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Laboratory_Test_Var_Samp_Order_By>;
  variance?: InputMaybe<Laboratory_Test_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Laboratory_Test_Avg_Fields = {
  __typename?: 'laboratory_test_avg_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "laboratory_test" */
export type Laboratory_Test_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "laboratory_test". All fields are combined with a logical 'AND'. */
export type Laboratory_Test_Bool_Exp = {
  _and?: InputMaybe<Array<Laboratory_Test_Bool_Exp>>;
  _not?: InputMaybe<Laboratory_Test_Bool_Exp>;
  _or?: InputMaybe<Array<Laboratory_Test_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  laboratory?: InputMaybe<Laboratory_Bool_Exp>;
  laboratoryId?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  test?: InputMaybe<Test_Bool_Exp>;
  testId?: InputMaybe<Int_Comparison_Exp>;
  waitingTime?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Laboratory_Test_Max_Fields = {
  __typename?: 'laboratory_test_max_fields';
  id?: Maybe<Scalars['Int']>;
  laboratoryId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  testId?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "laboratory_test_waiting_time_i18n" */
  waitingTime?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "laboratory_test" */
export type Laboratory_Test_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Laboratory_Test_Min_Fields = {
  __typename?: 'laboratory_test_min_fields';
  id?: Maybe<Scalars['Int']>;
  laboratoryId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  testId?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "laboratory_test_waiting_time_i18n" */
  waitingTime?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "laboratory_test" */
export type Laboratory_Test_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "laboratory_test" */
export type Laboratory_Test_Mutation_Response = {
  __typename?: 'laboratory_test_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Laboratory_Test>;
};

/** Ordering options when selecting data from "laboratory_test". */
export type Laboratory_Test_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratory?: InputMaybe<Laboratory_Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  test?: InputMaybe<Test_Order_By>;
  testId?: InputMaybe<Order_By>;
  waitingTime?: InputMaybe<Order_By>;
};

/** select columns of table "laboratory_test" */
export enum Laboratory_Test_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LaboratoryId = 'laboratoryId',
  /** column name */
  Price = 'price',
  /** column name */
  TestId = 'testId'
}

/** aggregate stddev on columns */
export type Laboratory_Test_Stddev_Fields = {
  __typename?: 'laboratory_test_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "laboratory_test" */
export type Laboratory_Test_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Laboratory_Test_Stddev_Pop_Fields = {
  __typename?: 'laboratory_test_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "laboratory_test" */
export type Laboratory_Test_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Laboratory_Test_Stddev_Samp_Fields = {
  __typename?: 'laboratory_test_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "laboratory_test" */
export type Laboratory_Test_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "laboratory_test" */
export type Laboratory_Test_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Laboratory_Test_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Laboratory_Test_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  laboratoryId?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  testId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Laboratory_Test_Sum_Fields = {
  __typename?: 'laboratory_test_sum_fields';
  id?: Maybe<Scalars['Int']>;
  laboratoryId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  testId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "laboratory_test" */
export type Laboratory_Test_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Laboratory_Test_Var_Pop_Fields = {
  __typename?: 'laboratory_test_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "laboratory_test" */
export type Laboratory_Test_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Laboratory_Test_Var_Samp_Fields = {
  __typename?: 'laboratory_test_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "laboratory_test" */
export type Laboratory_Test_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Laboratory_Test_Variance_Fields = {
  __typename?: 'laboratory_test_variance_fields';
  id?: Maybe<Scalars['Float']>;
  laboratoryId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  testId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "laboratory_test" */
export type Laboratory_Test_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  laboratoryId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "laboratory" */
export type Laboratory_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "laboratory" */
export type Laboratory_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "laboratory" */
export type Laboratory_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "laboratory_test" */
  delete_laboratory_test?: Maybe<Laboratory_Test_Mutation_Response>;
  /** delete single row from the table: "laboratory_test" */
  delete_laboratory_test_by_pk?: Maybe<Laboratory_Test>;
  /** delete data from the table: "order_test_result" */
  delete_order_test_result?: Maybe<Order_Test_Result_Mutation_Response>;
  /** delete single row from the table: "order_test_result" */
  delete_order_test_result_by_pk?: Maybe<Order_Test_Result>;
  /** insert data into the table: "file" */
  insert_file?: Maybe<File_Mutation_Response>;
  /** insert a single row into the table: "file" */
  insert_file_one?: Maybe<File>;
  /** insert data into the table: "order_state" */
  insert_order_state?: Maybe<Order_State_Mutation_Response>;
  /** insert a single row into the table: "order_state" */
  insert_order_state_one?: Maybe<Order_State>;
  /** insert data into the table: "order_test_result" */
  insert_order_test_result?: Maybe<Order_Test_Result_Mutation_Response>;
  /** insert a single row into the table: "order_test_result" */
  insert_order_test_result_one?: Maybe<Order_Test_Result>;
  lab_user_logout?: Maybe<LabUserLogoutOutput>;
  ping?: Maybe<PingOutput>;
  /** update data of the table: "order_test" */
  update_order_test?: Maybe<Order_Test_Mutation_Response>;
  /** update single row of the table: "order_test" */
  update_order_test_by_pk?: Maybe<Order_Test>;
  /** update multiples rows of table: "order_test" */
  update_order_test_many?: Maybe<Array<Maybe<Order_Test_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_Laboratory_TestArgs = {
  where: Laboratory_Test_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Laboratory_Test_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Order_Test_ResultArgs = {
  where: Order_Test_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Test_Result_By_PkArgs = {
  fileId: Scalars['Int'];
  orderTestId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_FileArgs = {
  objects: Array<File_Insert_Input>;
  on_conflict?: InputMaybe<File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_OneArgs = {
  object: File_Insert_Input;
  on_conflict?: InputMaybe<File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_StateArgs = {
  objects: Array<Order_State_Insert_Input>;
  on_conflict?: InputMaybe<Order_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_State_OneArgs = {
  object: Order_State_Insert_Input;
  on_conflict?: InputMaybe<Order_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Test_ResultArgs = {
  objects: Array<Order_Test_Result_Insert_Input>;
  on_conflict?: InputMaybe<Order_Test_Result_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Test_Result_OneArgs = {
  object: Order_Test_Result_Insert_Input;
  on_conflict?: InputMaybe<Order_Test_Result_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Order_TestArgs = {
  _set?: InputMaybe<Order_Test_Set_Input>;
  where: Order_Test_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Test_By_PkArgs = {
  _set?: InputMaybe<Order_Test_Set_Input>;
  pk_columns: Order_Test_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Test_ManyArgs = {
  updates: Array<Order_Test_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "nurse" */
export type Nurse = {
  __typename?: 'nurse';
  email: Scalars['String'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

/** Boolean expression to filter rows from the table "nurse". All fields are combined with a logical 'AND'. */
export type Nurse_Bool_Exp = {
  _and?: InputMaybe<Array<Nurse_Bool_Exp>>;
  _not?: InputMaybe<Nurse_Bool_Exp>;
  _or?: InputMaybe<Array<Nurse_Bool_Exp>>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "nurse". */
export type Nurse_Order_By = {
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
};

/** select columns of table "nurse" */
export enum Nurse_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phoneNumber'
}

/** Streaming cursor of the table "nurse" */
export type Nurse_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nurse_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nurse_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "order" */
export type Order = {
  __typename?: 'order';
  clientId: Scalars['Int'];
  currentState?: Maybe<Scalars['String']>;
  /** An array relationship */
  documents: Array<Order_Document>;
  /** An aggregate relationship */
  documents_aggregate: Order_Document_Aggregate;
  id: Scalars['Int'];
  /** An object relationship */
  nurse?: Maybe<Nurse>;
  /** An array relationship */
  orderCollections: Array<Order_Collection>;
  /** An aggregate relationship */
  orderCollections_aggregate: Order_Collection_Aggregate;
  /** An array relationship */
  orderStates: Array<Order_State>;
  /** An array relationship */
  orderTests: Array<Order_Test>;
  totalCost: Scalars['numeric'];
};


/** columns and relationships of "order" */
export type OrderDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Order_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Document_Order_By>>;
  where?: InputMaybe<Order_Document_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Document_Order_By>>;
  where?: InputMaybe<Order_Document_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderOrderCollectionsArgs = {
  distinct_on?: InputMaybe<Array<Order_Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Collection_Order_By>>;
  where?: InputMaybe<Order_Collection_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderOrderCollections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Collection_Order_By>>;
  where?: InputMaybe<Order_Collection_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderOrderStatesArgs = {
  distinct_on?: InputMaybe<Array<Order_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_State_Order_By>>;
  where?: InputMaybe<Order_State_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderOrderTestsArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Order_By>>;
  where?: InputMaybe<Order_Test_Bool_Exp>;
};

export type Order_Aggregate = {
  __typename?: 'order_aggregate';
  aggregate?: Maybe<Order_Aggregate_Fields>;
  nodes: Array<Order>;
};

/** aggregate fields of "order" */
export type Order_Aggregate_Fields = {
  __typename?: 'order_aggregate_fields';
  avg?: Maybe<Order_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Max_Fields>;
  min?: Maybe<Order_Min_Fields>;
  stddev?: Maybe<Order_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Sum_Fields>;
  var_pop?: Maybe<Order_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Var_Samp_Fields>;
  variance?: Maybe<Order_Variance_Fields>;
};


/** aggregate fields of "order" */
export type Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Order_Avg_Fields = {
  __typename?: 'order_avg_fields';
  clientId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "order". All fields are combined with a logical 'AND'. */
export type Order_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Bool_Exp>>;
  _not?: InputMaybe<Order_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Bool_Exp>>;
  clientId?: InputMaybe<Int_Comparison_Exp>;
  currentState?: InputMaybe<String_Comparison_Exp>;
  documents?: InputMaybe<Order_Document_Bool_Exp>;
  documents_aggregate?: InputMaybe<Order_Document_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  nurse?: InputMaybe<Nurse_Bool_Exp>;
  orderCollections?: InputMaybe<Order_Collection_Bool_Exp>;
  orderCollections_aggregate?: InputMaybe<Order_Collection_Aggregate_Bool_Exp>;
  orderStates?: InputMaybe<Order_State_Bool_Exp>;
  orderTests?: InputMaybe<Order_Test_Bool_Exp>;
  totalCost?: InputMaybe<Numeric_Comparison_Exp>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "order_collection" */
export type Order_Collection = {
  __typename?: 'order_collection';
  address: Scalars['String'];
  city: Scalars['String'];
  clientNotes?: Maybe<Scalars['String']>;
  clientQuarantine?: Maybe<Scalars['Boolean']>;
  collectionCost: Scalars['numeric'];
  collectionCostBeforeDiscount?: Maybe<Scalars['numeric']>;
  collectionDate: Scalars['timestamptz'];
  collectionKitCost: Scalars['numeric'];
  deliveryCost: Scalars['numeric'];
  id: Scalars['Int'];
  location: Scalars['geography'];
  /** An object relationship */
  nurse?: Maybe<Nurse>;
  nurseId?: Maybe<Scalars['Int']>;
  nurseNotes?: Maybe<Scalars['String']>;
  /** An object relationship */
  order: Order;
  orderId: Scalars['Int'];
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
};

/** aggregated selection of "order_collection" */
export type Order_Collection_Aggregate = {
  __typename?: 'order_collection_aggregate';
  aggregate?: Maybe<Order_Collection_Aggregate_Fields>;
  nodes: Array<Order_Collection>;
};

export type Order_Collection_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Order_Collection_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Order_Collection_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Order_Collection_Aggregate_Bool_Exp_Count>;
};

export type Order_Collection_Aggregate_Bool_Exp_Bool_And = {
  arguments: Order_Collection_Select_Column_Order_Collection_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Collection_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Order_Collection_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Order_Collection_Select_Column_Order_Collection_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Collection_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Order_Collection_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Collection_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Collection_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "order_collection" */
export type Order_Collection_Aggregate_Fields = {
  __typename?: 'order_collection_aggregate_fields';
  avg?: Maybe<Order_Collection_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Collection_Max_Fields>;
  min?: Maybe<Order_Collection_Min_Fields>;
  stddev?: Maybe<Order_Collection_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Collection_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Collection_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Collection_Sum_Fields>;
  var_pop?: Maybe<Order_Collection_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Collection_Var_Samp_Fields>;
  variance?: Maybe<Order_Collection_Variance_Fields>;
};


/** aggregate fields of "order_collection" */
export type Order_Collection_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Collection_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_collection" */
export type Order_Collection_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Collection_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Collection_Max_Order_By>;
  min?: InputMaybe<Order_Collection_Min_Order_By>;
  stddev?: InputMaybe<Order_Collection_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_Collection_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_Collection_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_Collection_Sum_Order_By>;
  var_pop?: InputMaybe<Order_Collection_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_Collection_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_Collection_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Order_Collection_Avg_Fields = {
  __typename?: 'order_collection_avg_fields';
  collectionCost?: Maybe<Scalars['Float']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nurseId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_collection" */
export type Order_Collection_Avg_Order_By = {
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_collection". All fields are combined with a logical 'AND'. */
export type Order_Collection_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Collection_Bool_Exp>>;
  _not?: InputMaybe<Order_Collection_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Collection_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  clientNotes?: InputMaybe<String_Comparison_Exp>;
  clientQuarantine?: InputMaybe<Boolean_Comparison_Exp>;
  collectionCost?: InputMaybe<Numeric_Comparison_Exp>;
  collectionCostBeforeDiscount?: InputMaybe<Numeric_Comparison_Exp>;
  collectionDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  collectionKitCost?: InputMaybe<Numeric_Comparison_Exp>;
  deliveryCost?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<Geography_Comparison_Exp>;
  nurse?: InputMaybe<Nurse_Bool_Exp>;
  nurseId?: InputMaybe<Int_Comparison_Exp>;
  nurseNotes?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Order_Bool_Exp>;
  orderId?: InputMaybe<Int_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  postalCode?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Order_Collection_Max_Fields = {
  __typename?: 'order_collection_max_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clientNotes?: Maybe<Scalars['String']>;
  collectionCost?: Maybe<Scalars['numeric']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['numeric']>;
  collectionDate?: Maybe<Scalars['timestamptz']>;
  collectionKitCost?: Maybe<Scalars['numeric']>;
  deliveryCost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  nurseId?: Maybe<Scalars['Int']>;
  nurseNotes?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "order_collection" */
export type Order_Collection_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  clientNotes?: InputMaybe<Order_By>;
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionDate?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  nurseNotes?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Collection_Min_Fields = {
  __typename?: 'order_collection_min_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clientNotes?: Maybe<Scalars['String']>;
  collectionCost?: Maybe<Scalars['numeric']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['numeric']>;
  collectionDate?: Maybe<Scalars['timestamptz']>;
  collectionKitCost?: Maybe<Scalars['numeric']>;
  deliveryCost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  nurseId?: Maybe<Scalars['Int']>;
  nurseNotes?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "order_collection" */
export type Order_Collection_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  clientNotes?: InputMaybe<Order_By>;
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionDate?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  nurseNotes?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "order_collection". */
export type Order_Collection_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  clientNotes?: InputMaybe<Order_By>;
  clientQuarantine?: InputMaybe<Order_By>;
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionDate?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  nurse?: InputMaybe<Nurse_Order_By>;
  nurseId?: InputMaybe<Order_By>;
  nurseNotes?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_Order_By>;
  orderId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
};

/** select columns of table "order_collection" */
export enum Order_Collection_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  ClientNotes = 'clientNotes',
  /** column name */
  ClientQuarantine = 'clientQuarantine',
  /** column name */
  CollectionCost = 'collectionCost',
  /** column name */
  CollectionCostBeforeDiscount = 'collectionCostBeforeDiscount',
  /** column name */
  CollectionDate = 'collectionDate',
  /** column name */
  CollectionKitCost = 'collectionKitCost',
  /** column name */
  DeliveryCost = 'deliveryCost',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  NurseId = 'nurseId',
  /** column name */
  NurseNotes = 'nurseNotes',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PostalCode = 'postalCode'
}

/** select "order_collection_aggregate_bool_exp_bool_and_arguments_columns" columns of table "order_collection" */
export enum Order_Collection_Select_Column_Order_Collection_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ClientQuarantine = 'clientQuarantine'
}

/** select "order_collection_aggregate_bool_exp_bool_or_arguments_columns" columns of table "order_collection" */
export enum Order_Collection_Select_Column_Order_Collection_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ClientQuarantine = 'clientQuarantine'
}

/** aggregate stddev on columns */
export type Order_Collection_Stddev_Fields = {
  __typename?: 'order_collection_stddev_fields';
  collectionCost?: Maybe<Scalars['Float']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nurseId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_collection" */
export type Order_Collection_Stddev_Order_By = {
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Collection_Stddev_Pop_Fields = {
  __typename?: 'order_collection_stddev_pop_fields';
  collectionCost?: Maybe<Scalars['Float']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nurseId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_collection" */
export type Order_Collection_Stddev_Pop_Order_By = {
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Collection_Stddev_Samp_Fields = {
  __typename?: 'order_collection_stddev_samp_fields';
  collectionCost?: Maybe<Scalars['Float']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nurseId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_collection" */
export type Order_Collection_Stddev_Samp_Order_By = {
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_collection" */
export type Order_Collection_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Collection_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Collection_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clientNotes?: InputMaybe<Scalars['String']>;
  clientQuarantine?: InputMaybe<Scalars['Boolean']>;
  collectionCost?: InputMaybe<Scalars['numeric']>;
  collectionCostBeforeDiscount?: InputMaybe<Scalars['numeric']>;
  collectionDate?: InputMaybe<Scalars['timestamptz']>;
  collectionKitCost?: InputMaybe<Scalars['numeric']>;
  deliveryCost?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['geography']>;
  nurseId?: InputMaybe<Scalars['Int']>;
  nurseNotes?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Order_Collection_Sum_Fields = {
  __typename?: 'order_collection_sum_fields';
  collectionCost?: Maybe<Scalars['numeric']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['numeric']>;
  collectionKitCost?: Maybe<Scalars['numeric']>;
  deliveryCost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  nurseId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_collection" */
export type Order_Collection_Sum_Order_By = {
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Order_Collection_Var_Pop_Fields = {
  __typename?: 'order_collection_var_pop_fields';
  collectionCost?: Maybe<Scalars['Float']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nurseId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_collection" */
export type Order_Collection_Var_Pop_Order_By = {
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Collection_Var_Samp_Fields = {
  __typename?: 'order_collection_var_samp_fields';
  collectionCost?: Maybe<Scalars['Float']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nurseId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_collection" */
export type Order_Collection_Var_Samp_Order_By = {
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Collection_Variance_Fields = {
  __typename?: 'order_collection_variance_fields';
  collectionCost?: Maybe<Scalars['Float']>;
  collectionCostBeforeDiscount?: Maybe<Scalars['Float']>;
  collectionKitCost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  nurseId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_collection" */
export type Order_Collection_Variance_Order_By = {
  collectionCost?: InputMaybe<Order_By>;
  collectionCostBeforeDiscount?: InputMaybe<Order_By>;
  collectionKitCost?: InputMaybe<Order_By>;
  deliveryCost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nurseId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** columns and relationships of "order_document" */
export type Order_Document = {
  __typename?: 'order_document';
  /** An object relationship */
  file: File;
  fileId: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  order: Order;
  orderId: Scalars['Int'];
};

/** aggregated selection of "order_document" */
export type Order_Document_Aggregate = {
  __typename?: 'order_document_aggregate';
  aggregate?: Maybe<Order_Document_Aggregate_Fields>;
  nodes: Array<Order_Document>;
};

export type Order_Document_Aggregate_Bool_Exp = {
  count?: InputMaybe<Order_Document_Aggregate_Bool_Exp_Count>;
};

export type Order_Document_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Document_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Document_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "order_document" */
export type Order_Document_Aggregate_Fields = {
  __typename?: 'order_document_aggregate_fields';
  avg?: Maybe<Order_Document_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Document_Max_Fields>;
  min?: Maybe<Order_Document_Min_Fields>;
  stddev?: Maybe<Order_Document_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Document_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Document_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Document_Sum_Fields>;
  var_pop?: Maybe<Order_Document_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Document_Var_Samp_Fields>;
  variance?: Maybe<Order_Document_Variance_Fields>;
};


/** aggregate fields of "order_document" */
export type Order_Document_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Document_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_document" */
export type Order_Document_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Document_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Document_Max_Order_By>;
  min?: InputMaybe<Order_Document_Min_Order_By>;
  stddev?: InputMaybe<Order_Document_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_Document_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_Document_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_Document_Sum_Order_By>;
  var_pop?: InputMaybe<Order_Document_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_Document_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_Document_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Order_Document_Avg_Fields = {
  __typename?: 'order_document_avg_fields';
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_document" */
export type Order_Document_Avg_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_document". All fields are combined with a logical 'AND'. */
export type Order_Document_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Document_Bool_Exp>>;
  _not?: InputMaybe<Order_Document_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Document_Bool_Exp>>;
  file?: InputMaybe<File_Bool_Exp>;
  fileId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Order_Bool_Exp>;
  orderId?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Order_Document_Max_Fields = {
  __typename?: 'order_document_max_fields';
  fileId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "order_document" */
export type Order_Document_Max_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Document_Min_Fields = {
  __typename?: 'order_document_min_fields';
  fileId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "order_document" */
export type Order_Document_Min_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "order_document". */
export type Order_Document_Order_By = {
  file?: InputMaybe<File_Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** select columns of table "order_document" */
export enum Order_Document_Select_Column {
  /** column name */
  FileId = 'fileId',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId'
}

/** aggregate stddev on columns */
export type Order_Document_Stddev_Fields = {
  __typename?: 'order_document_stddev_fields';
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_document" */
export type Order_Document_Stddev_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Document_Stddev_Pop_Fields = {
  __typename?: 'order_document_stddev_pop_fields';
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_document" */
export type Order_Document_Stddev_Pop_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Document_Stddev_Samp_Fields = {
  __typename?: 'order_document_stddev_samp_fields';
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_document" */
export type Order_Document_Stddev_Samp_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_document" */
export type Order_Document_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Document_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Document_Stream_Cursor_Value_Input = {
  fileId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Order_Document_Sum_Fields = {
  __typename?: 'order_document_sum_fields';
  fileId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_document" */
export type Order_Document_Sum_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Order_Document_Var_Pop_Fields = {
  __typename?: 'order_document_var_pop_fields';
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_document" */
export type Order_Document_Var_Pop_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Document_Var_Samp_Fields = {
  __typename?: 'order_document_var_samp_fields';
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_document" */
export type Order_Document_Var_Samp_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Document_Variance_Fields = {
  __typename?: 'order_document_variance_fields';
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_document" */
export type Order_Document_Variance_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Order_Max_Fields = {
  __typename?: 'order_max_fields';
  clientId?: Maybe<Scalars['Int']>;
  currentState?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  totalCost?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Order_Min_Fields = {
  __typename?: 'order_min_fields';
  clientId?: Maybe<Scalars['Int']>;
  currentState?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  totalCost?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "order". */
export type Order_Order_By = {
  clientId?: InputMaybe<Order_By>;
  currentState?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Order_Document_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  nurse?: InputMaybe<Nurse_Order_By>;
  orderCollections_aggregate?: InputMaybe<Order_Collection_Aggregate_Order_By>;
  orderStates_aggregate?: InputMaybe<Order_State_Aggregate_Order_By>;
  orderTests_aggregate?: InputMaybe<Order_Test_Aggregate_Order_By>;
  totalCost?: InputMaybe<Order_By>;
};

/** select columns of table "order" */
export enum Order_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Id = 'id',
  /** column name */
  TotalCost = 'totalCost'
}

/** columns and relationships of "order_state" */
export type Order_State = {
  __typename?: 'order_state';
  date: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  order: Order;
  orderId: Scalars['Int'];
  state: Order_State_Enum_Enum;
};

/** order by aggregate values of table "order_state" */
export type Order_State_Aggregate_Order_By = {
  avg?: InputMaybe<Order_State_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_State_Max_Order_By>;
  min?: InputMaybe<Order_State_Min_Order_By>;
  stddev?: InputMaybe<Order_State_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_State_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_State_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_State_Sum_Order_By>;
  var_pop?: InputMaybe<Order_State_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_State_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_State_Variance_Order_By>;
};

/** order by avg() on columns of table "order_state" */
export type Order_State_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_state". All fields are combined with a logical 'AND'. */
export type Order_State_Bool_Exp = {
  _and?: InputMaybe<Array<Order_State_Bool_Exp>>;
  _not?: InputMaybe<Order_State_Bool_Exp>;
  _or?: InputMaybe<Array<Order_State_Bool_Exp>>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Order_Bool_Exp>;
  orderId?: InputMaybe<Int_Comparison_Exp>;
  state?: InputMaybe<Order_State_Enum_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_state" */
export enum Order_State_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderStatePkey = 'order_state_pkey'
}

export enum Order_State_Enum_Enum {
  AwaitingClientConfirmation = 'AwaitingClientConfirmation',
  AwaitingConfirmation = 'AwaitingConfirmation',
  Cancelled = 'Cancelled',
  CollectingSamples = 'CollectingSamples',
  Confirmed = 'Confirmed',
  DifferentDateAccepted = 'DifferentDateAccepted',
  DifferentDateProposed = 'DifferentDateProposed',
  DifferentDateRejected = 'DifferentDateRejected',
  ExaminingSamples = 'ExaminingSamples',
  Finished = 'Finished',
  OnTheWayToClient = 'OnTheWayToClient',
  OnTheWayToLab = 'OnTheWayToLab',
  Unpaid = 'Unpaid'
}

/** Boolean expression to compare columns of type "order_state_enum_enum". All fields are combined with logical 'AND'. */
export type Order_State_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Order_State_Enum_Enum>;
  _in?: InputMaybe<Array<Order_State_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Order_State_Enum_Enum>;
  _nin?: InputMaybe<Array<Order_State_Enum_Enum>>;
};

/** input type for inserting data into table "order_state" */
export type Order_State_Insert_Input = {
  orderId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Order_State_Enum_Enum>;
};

/** order by max() on columns of table "order_state" */
export type Order_State_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "order_state" */
export type Order_State_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order_state" */
export type Order_State_Mutation_Response = {
  __typename?: 'order_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_State>;
};

/** on_conflict condition type for table "order_state" */
export type Order_State_On_Conflict = {
  constraint: Order_State_Constraint;
  update_columns?: Array<Order_State_Update_Column>;
  where?: InputMaybe<Order_State_Bool_Exp>;
};

/** Ordering options when selecting data from "order_state". */
export type Order_State_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_Order_By>;
  orderId?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** select columns of table "order_state" */
export enum Order_State_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  State = 'state'
}

/** order by stddev() on columns of table "order_state" */
export type Order_State_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_state" */
export type Order_State_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_state" */
export type Order_State_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_state" */
export type Order_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_State_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Order_State_Enum_Enum>;
};

/** order by sum() on columns of table "order_state" */
export type Order_State_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** placeholder for update columns of table "order_state" (current role has no relevant permissions) */
export enum Order_State_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** order by var_pop() on columns of table "order_state" */
export type Order_State_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "order_state" */
export type Order_State_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "order_state" */
export type Order_State_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Order_Stddev_Fields = {
  __typename?: 'order_stddev_fields';
  clientId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Order_Stddev_Pop_Fields = {
  __typename?: 'order_stddev_pop_fields';
  clientId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Order_Stddev_Samp_Fields = {
  __typename?: 'order_stddev_samp_fields';
  clientId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "order" */
export type Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  totalCost?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Order_Sum_Fields = {
  __typename?: 'order_sum_fields';
  clientId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  totalCost?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "order_test" */
export type Order_Test = {
  __typename?: 'order_test';
  collectedOn?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  collectionPoint?: Maybe<Laboratory_Collection_Point>;
  collectionPointId?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['numeric']>;
  finishedOn?: Maybe<Scalars['timestamptz']>;
  firstName: Scalars['String'];
  gender?: Maybe<Gender_Enum>;
  id: Scalars['Int'];
  identity?: Maybe<Scalars['jsonb']>;
  /** A computed field, executes function "order_test_laboratory" */
  laboratory?: Maybe<Array<Laboratory>>;
  laboratoryCode?: Maybe<Scalars['String']>;
  /** A computed field, executes function "order_test_laboratory_test" */
  laboratoryTest?: Maybe<Array<Laboratory_Test>>;
  lastName: Scalars['String'];
  /** An object relationship */
  order: Order;
  orderId: Scalars['Int'];
  retrievedOn?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  test: Test;
  testId: Scalars['Int'];
  /** An array relationship */
  testResults: Array<Order_Test_Result>;
  /** A computed field, executes function "get_order_test_results_count" */
  testResultsCount?: Maybe<Scalars['bigint']>;
  tubeCode?: Maybe<Scalars['String']>;
};


/** columns and relationships of "order_test" */
export type Order_TestIdentityArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "order_test" */
export type Order_TestLaboratoryArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


/** columns and relationships of "order_test" */
export type Order_TestLaboratoryTestArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


/** columns and relationships of "order_test" */
export type Order_TestTestResultsArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Result_Order_By>>;
  where?: InputMaybe<Order_Test_Result_Bool_Exp>;
};

/** order by aggregate values of table "order_test" */
export type Order_Test_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Test_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Test_Max_Order_By>;
  min?: InputMaybe<Order_Test_Min_Order_By>;
  stddev?: InputMaybe<Order_Test_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_Test_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_Test_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_Test_Sum_Order_By>;
  var_pop?: InputMaybe<Order_Test_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_Test_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_Test_Variance_Order_By>;
};

/** order by avg() on columns of table "order_test" */
export type Order_Test_Avg_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_test". All fields are combined with a logical 'AND'. */
export type Order_Test_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Test_Bool_Exp>>;
  _not?: InputMaybe<Order_Test_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Test_Bool_Exp>>;
  collectedOn?: InputMaybe<Timestamptz_Comparison_Exp>;
  collectionPoint?: InputMaybe<Laboratory_Collection_Point_Bool_Exp>;
  collectionPointId?: InputMaybe<Int_Comparison_Exp>;
  cost?: InputMaybe<Numeric_Comparison_Exp>;
  finishedOn?: InputMaybe<Timestamptz_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<Gender_Enum_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity?: InputMaybe<Jsonb_Comparison_Exp>;
  laboratory?: InputMaybe<Laboratory_Bool_Exp>;
  laboratoryCode?: InputMaybe<String_Comparison_Exp>;
  laboratoryTest?: InputMaybe<Laboratory_Test_Bool_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Order_Bool_Exp>;
  orderId?: InputMaybe<Int_Comparison_Exp>;
  retrievedOn?: InputMaybe<Timestamptz_Comparison_Exp>;
  test?: InputMaybe<Test_Bool_Exp>;
  testId?: InputMaybe<Int_Comparison_Exp>;
  testResults?: InputMaybe<Order_Test_Result_Bool_Exp>;
  testResultsCount?: InputMaybe<Bigint_Comparison_Exp>;
  tubeCode?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "order_test" */
export type Order_Test_Max_Order_By = {
  collectedOn?: InputMaybe<Order_By>;
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  finishedOn?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laboratoryCode?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  retrievedOn?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
  tubeCode?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "order_test" */
export type Order_Test_Min_Order_By = {
  collectedOn?: InputMaybe<Order_By>;
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  finishedOn?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laboratoryCode?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  retrievedOn?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
  tubeCode?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order_test" */
export type Order_Test_Mutation_Response = {
  __typename?: 'order_test_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Test>;
};

/** Ordering options when selecting data from "order_test". */
export type Order_Test_Order_By = {
  collectedOn?: InputMaybe<Order_By>;
  collectionPoint?: InputMaybe<Laboratory_Collection_Point_Order_By>;
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  finishedOn?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity?: InputMaybe<Order_By>;
  laboratoryCode?: InputMaybe<Order_By>;
  laboratoryTest_aggregate?: InputMaybe<Laboratory_Test_Aggregate_Order_By>;
  laboratory_aggregate?: InputMaybe<Laboratory_Aggregate_Order_By>;
  lastName?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_Order_By>;
  orderId?: InputMaybe<Order_By>;
  retrievedOn?: InputMaybe<Order_By>;
  test?: InputMaybe<Test_Order_By>;
  testId?: InputMaybe<Order_By>;
  testResultsCount?: InputMaybe<Order_By>;
  testResults_aggregate?: InputMaybe<Order_Test_Result_Aggregate_Order_By>;
  tubeCode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_test */
export type Order_Test_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** columns and relationships of "order_test_result" */
export type Order_Test_Result = {
  __typename?: 'order_test_result';
  criticalParameter: Scalars['Boolean'];
  date?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  file: File;
  fileId: Scalars['Int'];
  /** An object relationship */
  orderTest: Order_Test;
  orderTestId: Scalars['Int'];
  originalFilename: Scalars['String'];
};

/** order by aggregate values of table "order_test_result" */
export type Order_Test_Result_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Test_Result_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Test_Result_Max_Order_By>;
  min?: InputMaybe<Order_Test_Result_Min_Order_By>;
  stddev?: InputMaybe<Order_Test_Result_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_Test_Result_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_Test_Result_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_Test_Result_Sum_Order_By>;
  var_pop?: InputMaybe<Order_Test_Result_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_Test_Result_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_Test_Result_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_test_result" */
export type Order_Test_Result_Arr_Rel_Insert_Input = {
  data: Array<Order_Test_Result_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Test_Result_On_Conflict>;
};

/** order by avg() on columns of table "order_test_result" */
export type Order_Test_Result_Avg_Order_By = {
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_test_result". All fields are combined with a logical 'AND'. */
export type Order_Test_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Test_Result_Bool_Exp>>;
  _not?: InputMaybe<Order_Test_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Test_Result_Bool_Exp>>;
  criticalParameter?: InputMaybe<Boolean_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<File_Bool_Exp>;
  fileId?: InputMaybe<Int_Comparison_Exp>;
  orderTest?: InputMaybe<Order_Test_Bool_Exp>;
  orderTestId?: InputMaybe<Int_Comparison_Exp>;
  originalFilename?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_test_result" */
export enum Order_Test_Result_Constraint {
  /** unique or primary key constraint on columns "order_test_id", "file_id" */
  OrderTestResultPkey = 'order_test_result_pkey'
}

/** input type for inserting data into table "order_test_result" */
export type Order_Test_Result_Insert_Input = {
  criticalParameter?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  file?: InputMaybe<File_Obj_Rel_Insert_Input>;
  fileId?: InputMaybe<Scalars['Int']>;
  orderTestId?: InputMaybe<Scalars['Int']>;
  originalFilename?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "order_test_result" */
export type Order_Test_Result_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
  originalFilename?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "order_test_result" */
export type Order_Test_Result_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
  originalFilename?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order_test_result" */
export type Order_Test_Result_Mutation_Response = {
  __typename?: 'order_test_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Test_Result>;
};

/** on_conflict condition type for table "order_test_result" */
export type Order_Test_Result_On_Conflict = {
  constraint: Order_Test_Result_Constraint;
  update_columns?: Array<Order_Test_Result_Update_Column>;
  where?: InputMaybe<Order_Test_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "order_test_result". */
export type Order_Test_Result_Order_By = {
  criticalParameter?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  file?: InputMaybe<File_Order_By>;
  fileId?: InputMaybe<Order_By>;
  orderTest?: InputMaybe<Order_Test_Order_By>;
  orderTestId?: InputMaybe<Order_By>;
  originalFilename?: InputMaybe<Order_By>;
};

/** select columns of table "order_test_result" */
export enum Order_Test_Result_Select_Column {
  /** column name */
  CriticalParameter = 'criticalParameter',
  /** column name */
  Date = 'date',
  /** column name */
  FileId = 'fileId',
  /** column name */
  OrderTestId = 'orderTestId',
  /** column name */
  OriginalFilename = 'originalFilename'
}

/** order by stddev() on columns of table "order_test_result" */
export type Order_Test_Result_Stddev_Order_By = {
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_test_result" */
export type Order_Test_Result_Stddev_Pop_Order_By = {
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_test_result" */
export type Order_Test_Result_Stddev_Samp_Order_By = {
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_test_result" */
export type Order_Test_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Test_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Test_Result_Stream_Cursor_Value_Input = {
  criticalParameter?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  fileId?: InputMaybe<Scalars['Int']>;
  orderTestId?: InputMaybe<Scalars['Int']>;
  originalFilename?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "order_test_result" */
export type Order_Test_Result_Sum_Order_By = {
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** placeholder for update columns of table "order_test_result" (current role has no relevant permissions) */
export enum Order_Test_Result_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** order by var_pop() on columns of table "order_test_result" */
export type Order_Test_Result_Var_Pop_Order_By = {
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "order_test_result" */
export type Order_Test_Result_Var_Samp_Order_By = {
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "order_test_result" */
export type Order_Test_Result_Variance_Order_By = {
  fileId?: InputMaybe<Order_By>;
  orderTestId?: InputMaybe<Order_By>;
};

/** select columns of table "order_test" */
export enum Order_Test_Select_Column {
  /** column name */
  CollectedOn = 'collectedOn',
  /** column name */
  CollectionPointId = 'collectionPointId',
  /** column name */
  Cost = 'cost',
  /** column name */
  FinishedOn = 'finishedOn',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  Identity = 'identity',
  /** column name */
  LaboratoryCode = 'laboratoryCode',
  /** column name */
  LastName = 'lastName',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  RetrievedOn = 'retrievedOn',
  /** column name */
  TestId = 'testId',
  /** column name */
  TubeCode = 'tubeCode'
}

/** input type for updating data in table "order_test" */
export type Order_Test_Set_Input = {
  finishedOn?: InputMaybe<Scalars['timestamptz']>;
  laboratoryCode?: InputMaybe<Scalars['String']>;
  retrievedOn?: InputMaybe<Scalars['timestamptz']>;
};

/** order by stddev() on columns of table "order_test" */
export type Order_Test_Stddev_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_test" */
export type Order_Test_Stddev_Pop_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_test" */
export type Order_Test_Stddev_Samp_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_test" */
export type Order_Test_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Test_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Test_Stream_Cursor_Value_Input = {
  collectedOn?: InputMaybe<Scalars['timestamptz']>;
  collectionPointId?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['numeric']>;
  finishedOn?: InputMaybe<Scalars['timestamptz']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  identity?: InputMaybe<Scalars['jsonb']>;
  laboratoryCode?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['Int']>;
  retrievedOn?: InputMaybe<Scalars['timestamptz']>;
  testId?: InputMaybe<Scalars['Int']>;
  tubeCode?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "order_test" */
export type Order_Test_Sum_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

export type Order_Test_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Test_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_Test_Bool_Exp;
};

/** order by var_pop() on columns of table "order_test" */
export type Order_Test_Var_Pop_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "order_test" */
export type Order_Test_Var_Samp_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "order_test" */
export type Order_Test_Variance_Order_By = {
  collectionPointId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  testId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Order_Var_Pop_Fields = {
  __typename?: 'order_var_pop_fields';
  clientId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Order_Var_Samp_Fields = {
  __typename?: 'order_var_samp_fields';
  clientId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Order_Variance_Fields = {
  __typename?: 'order_variance_fields';
  clientId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** execute function "available_laboratory" which returns "laboratory" */
  available_laboratory: Array<Laboratory>;
  /** fetch data from the table: "file" */
  file: Array<File>;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table: "gender" */
  gender: Array<Gender>;
  /** fetch data from the table: "gender" using primary key columns */
  gender_by_pk?: Maybe<Gender>;
  /** fetch data from the table: "lab_user" */
  lab_user: Array<Lab_User>;
  /** fetch data from the table: "lab_user" using primary key columns */
  lab_user_by_pk?: Maybe<Lab_User>;
  /** fetch data from the table: "laboratory" */
  laboratory: Array<Laboratory>;
  /** fetch data from the table: "laboratory" using primary key columns */
  laboratory_by_pk?: Maybe<Laboratory>;
  /** fetch data from the table: "laboratory_collection_point" */
  laboratory_collection_point: Array<Laboratory_Collection_Point>;
  /** fetch data from the table: "laboratory_collection_point" using primary key columns */
  laboratory_collection_point_by_pk?: Maybe<Laboratory_Collection_Point>;
  /** fetch data from the table: "laboratory_test" */
  laboratory_test: Array<Laboratory_Test>;
  /** fetch aggregated fields from the table: "laboratory_test" */
  laboratory_test_aggregate: Laboratory_Test_Aggregate;
  /** fetch data from the table: "laboratory_test" using primary key columns */
  laboratory_test_by_pk?: Maybe<Laboratory_Test>;
  /** execute function "nearby_unconfirmed_order" which returns "order" */
  nearby_unconfirmed_order: Array<Order>;
  /** execute function "nearby_unconfirmed_order" and query aggregates on result of table type "order" */
  nearby_unconfirmed_order_aggregate: Order_Aggregate;
  /** fetch data from the table: "nurse" */
  nurse: Array<Nurse>;
  /** fetch data from the table: "nurse" using primary key columns */
  nurse_by_pk?: Maybe<Nurse>;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch aggregated fields from the table: "order" */
  order_aggregate: Order_Aggregate;
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "order_collection" */
  order_collection: Array<Order_Collection>;
  /** fetch aggregated fields from the table: "order_collection" */
  order_collection_aggregate: Order_Collection_Aggregate;
  /** fetch data from the table: "order_collection" using primary key columns */
  order_collection_by_pk?: Maybe<Order_Collection>;
  /** fetch data from the table: "order_document" */
  order_document: Array<Order_Document>;
  /** fetch aggregated fields from the table: "order_document" */
  order_document_aggregate: Order_Document_Aggregate;
  /** fetch data from the table: "order_document" using primary key columns */
  order_document_by_pk?: Maybe<Order_Document>;
  /** fetch data from the table: "order_state" */
  order_state: Array<Order_State>;
  /** fetch data from the table: "order_state" using primary key columns */
  order_state_by_pk?: Maybe<Order_State>;
  /** fetch data from the table: "order_test" */
  order_test: Array<Order_Test>;
  /** fetch data from the table: "order_test" using primary key columns */
  order_test_by_pk?: Maybe<Order_Test>;
  /** fetch data from the table: "order_test_result" */
  order_test_result: Array<Order_Test_Result>;
  /** fetch data from the table: "order_test_result" using primary key columns */
  order_test_result_by_pk?: Maybe<Order_Test_Result>;
  /** execute function "search_test" which returns "test" */
  search_test: Array<Test>;
  /** execute function "search_test_most_popular" which returns "test" */
  search_test_most_popular: Array<Test>;
  /** execute function "search_test_tag" which returns "test" */
  search_test_tag: Array<Test>;
  /** fetch data from the table: "test" */
  test: Array<Test>;
  /** fetch data from the table: "test" using primary key columns */
  test_by_pk?: Maybe<Test>;
  /** execute function "test_laboratories" which returns "laboratory" */
  test_laboratories: Array<Laboratory>;
  /** execute function "tests_by_collection_point_and_nurse" which returns "test" */
  tests_by_collection_point_and_nurse: Array<Test>;
  /** fetch data from the table: "training" */
  training: Array<Training>;
  /** fetch aggregated fields from the table: "training" */
  training_aggregate: Training_Aggregate;
  /** fetch data from the table: "training" using primary key columns */
  training_by_pk?: Maybe<Training>;
};


export type Query_RootAvailable_LaboratoryArgs = {
  args: Available_Laboratory_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Query_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Query_RootFile_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootGenderArgs = {
  distinct_on?: InputMaybe<Array<Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gender_Order_By>>;
  where?: InputMaybe<Gender_Bool_Exp>;
};


export type Query_RootGender_By_PkArgs = {
  gender: Scalars['String'];
};


export type Query_RootLab_UserArgs = {
  distinct_on?: InputMaybe<Array<Lab_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lab_User_Order_By>>;
  where?: InputMaybe<Lab_User_Bool_Exp>;
};


export type Query_RootLab_User_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLaboratoryArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Query_RootLaboratory_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLaboratory_Collection_PointArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Collection_Point_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Collection_Point_Order_By>>;
  where?: InputMaybe<Laboratory_Collection_Point_Bool_Exp>;
};


export type Query_RootLaboratory_Collection_Point_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLaboratory_TestArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


export type Query_RootLaboratory_Test_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


export type Query_RootLaboratory_Test_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootNearby_Unconfirmed_OrderArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Query_RootNearby_Unconfirmed_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Query_RootNurseArgs = {
  distinct_on?: InputMaybe<Array<Nurse_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nurse_Order_By>>;
  where?: InputMaybe<Nurse_Bool_Exp>;
};


export type Query_RootNurse_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrderArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Query_RootOrder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Query_RootOrder_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_CollectionArgs = {
  distinct_on?: InputMaybe<Array<Order_Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Collection_Order_By>>;
  where?: InputMaybe<Order_Collection_Bool_Exp>;
};


export type Query_RootOrder_Collection_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Collection_Order_By>>;
  where?: InputMaybe<Order_Collection_Bool_Exp>;
};


export type Query_RootOrder_Collection_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_DocumentArgs = {
  distinct_on?: InputMaybe<Array<Order_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Document_Order_By>>;
  where?: InputMaybe<Order_Document_Bool_Exp>;
};


export type Query_RootOrder_Document_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Document_Order_By>>;
  where?: InputMaybe<Order_Document_Bool_Exp>;
};


export type Query_RootOrder_Document_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_StateArgs = {
  distinct_on?: InputMaybe<Array<Order_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_State_Order_By>>;
  where?: InputMaybe<Order_State_Bool_Exp>;
};


export type Query_RootOrder_State_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_TestArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Order_By>>;
  where?: InputMaybe<Order_Test_Bool_Exp>;
};


export type Query_RootOrder_Test_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_Test_ResultArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Result_Order_By>>;
  where?: InputMaybe<Order_Test_Result_Bool_Exp>;
};


export type Query_RootOrder_Test_Result_By_PkArgs = {
  fileId: Scalars['Int'];
  orderTestId: Scalars['Int'];
};


export type Query_RootSearch_TestArgs = {
  args: Search_Test_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootSearch_Test_Most_PopularArgs = {
  args: Search_Test_Most_Popular_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootSearch_Test_TagArgs = {
  args: Search_Test_Tag_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootTestArgs = {
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootTest_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTest_LaboratoriesArgs = {
  args: Test_Laboratories_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Query_RootTests_By_Collection_Point_And_NurseArgs = {
  args: Tests_By_Collection_Point_And_Nurse_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootTrainingArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};


export type Query_RootTraining_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};


export type Query_RootTraining_By_PkArgs = {
  id: Scalars['Int'];
};

export type Search_Test_Args = {
  _limit?: InputMaybe<Scalars['Int']>;
  _offset?: InputMaybe<Scalars['Int']>;
  point?: InputMaybe<Scalars['geography']>;
  query?: InputMaybe<Scalars['String']>;
};

export type Search_Test_Most_Popular_Args = {
  _limit?: InputMaybe<Scalars['Int']>;
  _offset?: InputMaybe<Scalars['Int']>;
  point?: InputMaybe<Scalars['geography']>;
};

export type Search_Test_Tag_Args = {
  _limit?: InputMaybe<Scalars['Int']>;
  _offset?: InputMaybe<Scalars['Int']>;
  _tag?: InputMaybe<Scalars['String']>;
  point?: InputMaybe<Scalars['geography']>;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: InputMaybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** execute function "available_laboratory" which returns "laboratory" */
  available_laboratory: Array<Laboratory>;
  /** fetch data from the table: "file" */
  file: Array<File>;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table in a streaming manner: "file" */
  file_stream: Array<File>;
  /** fetch data from the table: "gender" */
  gender: Array<Gender>;
  /** fetch data from the table: "gender" using primary key columns */
  gender_by_pk?: Maybe<Gender>;
  /** fetch data from the table in a streaming manner: "gender" */
  gender_stream: Array<Gender>;
  /** fetch data from the table: "lab_user" */
  lab_user: Array<Lab_User>;
  /** fetch data from the table: "lab_user" using primary key columns */
  lab_user_by_pk?: Maybe<Lab_User>;
  /** fetch data from the table in a streaming manner: "lab_user" */
  lab_user_stream: Array<Lab_User>;
  /** fetch data from the table: "laboratory" */
  laboratory: Array<Laboratory>;
  /** fetch data from the table: "laboratory" using primary key columns */
  laboratory_by_pk?: Maybe<Laboratory>;
  /** fetch data from the table: "laboratory_collection_point" */
  laboratory_collection_point: Array<Laboratory_Collection_Point>;
  /** fetch data from the table: "laboratory_collection_point" using primary key columns */
  laboratory_collection_point_by_pk?: Maybe<Laboratory_Collection_Point>;
  /** fetch data from the table in a streaming manner: "laboratory_collection_point" */
  laboratory_collection_point_stream: Array<Laboratory_Collection_Point>;
  /** fetch data from the table in a streaming manner: "laboratory" */
  laboratory_stream: Array<Laboratory>;
  /** fetch data from the table: "laboratory_test" */
  laboratory_test: Array<Laboratory_Test>;
  /** fetch aggregated fields from the table: "laboratory_test" */
  laboratory_test_aggregate: Laboratory_Test_Aggregate;
  /** fetch data from the table: "laboratory_test" using primary key columns */
  laboratory_test_by_pk?: Maybe<Laboratory_Test>;
  /** fetch data from the table in a streaming manner: "laboratory_test" */
  laboratory_test_stream: Array<Laboratory_Test>;
  /** execute function "nearby_unconfirmed_order" which returns "order" */
  nearby_unconfirmed_order: Array<Order>;
  /** execute function "nearby_unconfirmed_order" and query aggregates on result of table type "order" */
  nearby_unconfirmed_order_aggregate: Order_Aggregate;
  /** fetch data from the table: "nurse" */
  nurse: Array<Nurse>;
  /** fetch data from the table: "nurse" using primary key columns */
  nurse_by_pk?: Maybe<Nurse>;
  /** fetch data from the table in a streaming manner: "nurse" */
  nurse_stream: Array<Nurse>;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch aggregated fields from the table: "order" */
  order_aggregate: Order_Aggregate;
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "order_collection" */
  order_collection: Array<Order_Collection>;
  /** fetch aggregated fields from the table: "order_collection" */
  order_collection_aggregate: Order_Collection_Aggregate;
  /** fetch data from the table: "order_collection" using primary key columns */
  order_collection_by_pk?: Maybe<Order_Collection>;
  /** fetch data from the table in a streaming manner: "order_collection" */
  order_collection_stream: Array<Order_Collection>;
  /** fetch data from the table: "order_document" */
  order_document: Array<Order_Document>;
  /** fetch aggregated fields from the table: "order_document" */
  order_document_aggregate: Order_Document_Aggregate;
  /** fetch data from the table: "order_document" using primary key columns */
  order_document_by_pk?: Maybe<Order_Document>;
  /** fetch data from the table in a streaming manner: "order_document" */
  order_document_stream: Array<Order_Document>;
  /** fetch data from the table: "order_state" */
  order_state: Array<Order_State>;
  /** fetch data from the table: "order_state" using primary key columns */
  order_state_by_pk?: Maybe<Order_State>;
  /** fetch data from the table in a streaming manner: "order_state" */
  order_state_stream: Array<Order_State>;
  /** fetch data from the table in a streaming manner: "order" */
  order_stream: Array<Order>;
  /** fetch data from the table: "order_test" */
  order_test: Array<Order_Test>;
  /** fetch data from the table: "order_test" using primary key columns */
  order_test_by_pk?: Maybe<Order_Test>;
  /** fetch data from the table: "order_test_result" */
  order_test_result: Array<Order_Test_Result>;
  /** fetch data from the table: "order_test_result" using primary key columns */
  order_test_result_by_pk?: Maybe<Order_Test_Result>;
  /** fetch data from the table in a streaming manner: "order_test_result" */
  order_test_result_stream: Array<Order_Test_Result>;
  /** fetch data from the table in a streaming manner: "order_test" */
  order_test_stream: Array<Order_Test>;
  /** execute function "search_test" which returns "test" */
  search_test: Array<Test>;
  /** execute function "search_test_most_popular" which returns "test" */
  search_test_most_popular: Array<Test>;
  /** execute function "search_test_tag" which returns "test" */
  search_test_tag: Array<Test>;
  /** fetch data from the table: "test" */
  test: Array<Test>;
  /** fetch data from the table: "test" using primary key columns */
  test_by_pk?: Maybe<Test>;
  /** execute function "test_laboratories" which returns "laboratory" */
  test_laboratories: Array<Laboratory>;
  /** fetch data from the table in a streaming manner: "test" */
  test_stream: Array<Test>;
  /** execute function "tests_by_collection_point_and_nurse" which returns "test" */
  tests_by_collection_point_and_nurse: Array<Test>;
  /** fetch data from the table: "training" */
  training: Array<Training>;
  /** fetch aggregated fields from the table: "training" */
  training_aggregate: Training_Aggregate;
  /** fetch data from the table: "training" using primary key columns */
  training_by_pk?: Maybe<Training>;
  /** fetch data from the table in a streaming manner: "training" */
  training_stream: Array<Training>;
};


export type Subscription_RootAvailable_LaboratoryArgs = {
  args: Available_Laboratory_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootFile_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<File_Stream_Cursor_Input>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootGenderArgs = {
  distinct_on?: InputMaybe<Array<Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gender_Order_By>>;
  where?: InputMaybe<Gender_Bool_Exp>;
};


export type Subscription_RootGender_By_PkArgs = {
  gender: Scalars['String'];
};


export type Subscription_RootGender_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Gender_Stream_Cursor_Input>>;
  where?: InputMaybe<Gender_Bool_Exp>;
};


export type Subscription_RootLab_UserArgs = {
  distinct_on?: InputMaybe<Array<Lab_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lab_User_Order_By>>;
  where?: InputMaybe<Lab_User_Bool_Exp>;
};


export type Subscription_RootLab_User_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLab_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lab_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Lab_User_Bool_Exp>;
};


export type Subscription_RootLaboratoryArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootLaboratory_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLaboratory_Collection_PointArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Collection_Point_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Collection_Point_Order_By>>;
  where?: InputMaybe<Laboratory_Collection_Point_Bool_Exp>;
};


export type Subscription_RootLaboratory_Collection_Point_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLaboratory_Collection_Point_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Laboratory_Collection_Point_Stream_Cursor_Input>>;
  where?: InputMaybe<Laboratory_Collection_Point_Bool_Exp>;
};


export type Subscription_RootLaboratory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Laboratory_Stream_Cursor_Input>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootLaboratory_TestArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


export type Subscription_RootLaboratory_Test_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


export type Subscription_RootLaboratory_Test_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLaboratory_Test_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Laboratory_Test_Stream_Cursor_Input>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


export type Subscription_RootNearby_Unconfirmed_OrderArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootNearby_Unconfirmed_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootNurseArgs = {
  distinct_on?: InputMaybe<Array<Nurse_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nurse_Order_By>>;
  where?: InputMaybe<Nurse_Bool_Exp>;
};


export type Subscription_RootNurse_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootNurse_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nurse_Stream_Cursor_Input>>;
  where?: InputMaybe<Nurse_Bool_Exp>;
};


export type Subscription_RootOrderArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootOrder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootOrder_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_CollectionArgs = {
  distinct_on?: InputMaybe<Array<Order_Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Collection_Order_By>>;
  where?: InputMaybe<Order_Collection_Bool_Exp>;
};


export type Subscription_RootOrder_Collection_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Collection_Order_By>>;
  where?: InputMaybe<Order_Collection_Bool_Exp>;
};


export type Subscription_RootOrder_Collection_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Collection_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Order_Collection_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Collection_Bool_Exp>;
};


export type Subscription_RootOrder_DocumentArgs = {
  distinct_on?: InputMaybe<Array<Order_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Document_Order_By>>;
  where?: InputMaybe<Order_Document_Bool_Exp>;
};


export type Subscription_RootOrder_Document_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Document_Order_By>>;
  where?: InputMaybe<Order_Document_Bool_Exp>;
};


export type Subscription_RootOrder_Document_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Document_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Order_Document_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Document_Bool_Exp>;
};


export type Subscription_RootOrder_StateArgs = {
  distinct_on?: InputMaybe<Array<Order_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_State_Order_By>>;
  where?: InputMaybe<Order_State_Bool_Exp>;
};


export type Subscription_RootOrder_State_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Order_State_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_State_Bool_Exp>;
};


export type Subscription_RootOrder_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Order_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootOrder_TestArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Order_By>>;
  where?: InputMaybe<Order_Test_Bool_Exp>;
};


export type Subscription_RootOrder_Test_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Test_ResultArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Result_Order_By>>;
  where?: InputMaybe<Order_Test_Result_Bool_Exp>;
};


export type Subscription_RootOrder_Test_Result_By_PkArgs = {
  fileId: Scalars['Int'];
  orderTestId: Scalars['Int'];
};


export type Subscription_RootOrder_Test_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Order_Test_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Test_Result_Bool_Exp>;
};


export type Subscription_RootOrder_Test_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Order_Test_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Test_Bool_Exp>;
};


export type Subscription_RootSearch_TestArgs = {
  args: Search_Test_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootSearch_Test_Most_PopularArgs = {
  args: Search_Test_Most_Popular_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootSearch_Test_TagArgs = {
  args: Search_Test_Tag_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootTestArgs = {
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootTest_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTest_LaboratoriesArgs = {
  args: Test_Laboratories_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Order_By>>;
  where?: InputMaybe<Laboratory_Bool_Exp>;
};


export type Subscription_RootTest_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Test_Stream_Cursor_Input>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootTests_By_Collection_Point_And_NurseArgs = {
  args: Tests_By_Collection_Point_And_Nurse_Args;
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootTrainingArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};


export type Subscription_RootTraining_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Training_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Training_Order_By>>;
  where?: InputMaybe<Training_Bool_Exp>;
};


export type Subscription_RootTraining_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTraining_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Training_Stream_Cursor_Input>>;
  where?: InputMaybe<Training_Bool_Exp>;
};

/** columns and relationships of "test" */
export type Test = {
  __typename?: 'test';
  /** A computed field, executes function "test_laboratory_tests" */
  availableLaboratoryTests?: Maybe<Array<Laboratory_Test>>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "test_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A computed field, executes function "test_instructions_i18n" */
  instructions?: Maybe<Scalars['String']>;
  /** A computed field, executes function "test_lab_instructions_i18n" */
  labInstructions?: Maybe<Scalars['String']>;
  /** An array relationship */
  laboratoryTests: Array<Laboratory_Test>;
  /** An aggregate relationship */
  laboratoryTests_aggregate: Laboratory_Test_Aggregate;
  /** A computed field, executes function "test_name_i18n" */
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  orderTests: Array<Order_Test>;
  /** A computed field, executes function "test_preparation_i18n" */
  preparation?: Maybe<Scalars['String']>;
  /** A computed field, executes function "test_price" */
  price?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "test_short_name_i18n" */
  shortName?: Maybe<Scalars['String']>;
  svgIcon?: Maybe<Scalars['String']>;
  /** A computed field, executes function "test_waiting_time_i18n" */
  waitingTime?: Maybe<Scalars['String']>;
};


/** columns and relationships of "test" */
export type TestAvailableLaboratoryTestsArgs = {
  args: AvailableLaboratoryTests_Test_Args;
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


/** columns and relationships of "test" */
export type TestLaboratoryTestsArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


/** columns and relationships of "test" */
export type TestLaboratoryTests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Laboratory_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Laboratory_Test_Order_By>>;
  where?: InputMaybe<Laboratory_Test_Bool_Exp>;
};


/** columns and relationships of "test" */
export type TestOrderTestsArgs = {
  distinct_on?: InputMaybe<Array<Order_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Order_Test_Order_By>>;
  where?: InputMaybe<Order_Test_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "test". All fields are combined with a logical 'AND'. */
export type Test_Bool_Exp = {
  _and?: InputMaybe<Array<Test_Bool_Exp>>;
  _not?: InputMaybe<Test_Bool_Exp>;
  _or?: InputMaybe<Array<Test_Bool_Exp>>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  instructions?: InputMaybe<String_Comparison_Exp>;
  labInstructions?: InputMaybe<String_Comparison_Exp>;
  laboratoryTests?: InputMaybe<Laboratory_Test_Bool_Exp>;
  laboratoryTests_aggregate?: InputMaybe<Laboratory_Test_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  orderTests?: InputMaybe<Order_Test_Bool_Exp>;
  preparation?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  shortName?: InputMaybe<String_Comparison_Exp>;
  svgIcon?: InputMaybe<String_Comparison_Exp>;
  waitingTime?: InputMaybe<String_Comparison_Exp>;
};

export type Test_Laboratories_Args = {
  _test_id?: InputMaybe<Scalars['Int']>;
  point?: InputMaybe<Scalars['geography']>;
};

/** Ordering options when selecting data from "test". */
export type Test_Order_By = {
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instructions?: InputMaybe<Order_By>;
  labInstructions?: InputMaybe<Order_By>;
  laboratoryTests_aggregate?: InputMaybe<Laboratory_Test_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  orderTests_aggregate?: InputMaybe<Order_Test_Aggregate_Order_By>;
  preparation?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  shortName?: InputMaybe<Order_By>;
  svgIcon?: InputMaybe<Order_By>;
  waitingTime?: InputMaybe<Order_By>;
};

/** select columns of table "test" */
export enum Test_Select_Column {
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  SvgIcon = 'svgIcon'
}

/** Streaming cursor of the table "test" */
export type Test_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Test_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Test_Stream_Cursor_Value_Input = {
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  svgIcon?: InputMaybe<Scalars['String']>;
};

export type Tests_By_Collection_Point_And_Nurse_Args = {
  collectionpointid?: InputMaybe<Scalars['Int']>;
  nurseid?: InputMaybe<Scalars['Int']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "training" */
export type Training = {
  __typename?: 'training';
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "training_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A computed field, executes function "training_name_i18n" */
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  previewFile: File;
  previewFileId: Scalars['Int'];
  price: Scalars['numeric'];
  /** An object relationship */
  videoFile: File;
  videoFileId: Scalars['Int'];
};

/** aggregated selection of "training" */
export type Training_Aggregate = {
  __typename?: 'training_aggregate';
  aggregate?: Maybe<Training_Aggregate_Fields>;
  nodes: Array<Training>;
};

export type Training_Aggregate_Bool_Exp = {
  count?: InputMaybe<Training_Aggregate_Bool_Exp_Count>;
};

export type Training_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Training_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Training_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "training" */
export type Training_Aggregate_Fields = {
  __typename?: 'training_aggregate_fields';
  avg?: Maybe<Training_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Training_Max_Fields>;
  min?: Maybe<Training_Min_Fields>;
  stddev?: Maybe<Training_Stddev_Fields>;
  stddev_pop?: Maybe<Training_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Training_Stddev_Samp_Fields>;
  sum?: Maybe<Training_Sum_Fields>;
  var_pop?: Maybe<Training_Var_Pop_Fields>;
  var_samp?: Maybe<Training_Var_Samp_Fields>;
  variance?: Maybe<Training_Variance_Fields>;
};


/** aggregate fields of "training" */
export type Training_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Training_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "training" */
export type Training_Aggregate_Order_By = {
  avg?: InputMaybe<Training_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Training_Max_Order_By>;
  min?: InputMaybe<Training_Min_Order_By>;
  stddev?: InputMaybe<Training_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Training_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Training_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Training_Sum_Order_By>;
  var_pop?: InputMaybe<Training_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Training_Var_Samp_Order_By>;
  variance?: InputMaybe<Training_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Training_Avg_Fields = {
  __typename?: 'training_avg_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "training" */
export type Training_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "training". All fields are combined with a logical 'AND'. */
export type Training_Bool_Exp = {
  _and?: InputMaybe<Array<Training_Bool_Exp>>;
  _not?: InputMaybe<Training_Bool_Exp>;
  _or?: InputMaybe<Array<Training_Bool_Exp>>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  previewFile?: InputMaybe<File_Bool_Exp>;
  previewFileId?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  videoFile?: InputMaybe<File_Bool_Exp>;
  videoFileId?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Training_Max_Fields = {
  __typename?: 'training_max_fields';
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "training_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "training_name_i18n" */
  name?: Maybe<Scalars['String']>;
  previewFileId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  videoFileId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "training" */
export type Training_Max_Order_By = {
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Training_Min_Fields = {
  __typename?: 'training_min_fields';
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "training_description_i18n" */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "training_name_i18n" */
  name?: Maybe<Scalars['String']>;
  previewFileId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  videoFileId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "training" */
export type Training_Min_Order_By = {
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "training". */
export type Training_Order_By = {
  deletedAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  previewFile?: InputMaybe<File_Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFile?: InputMaybe<File_Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** select columns of table "training" */
export enum Training_Select_Column {
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  PreviewFileId = 'previewFileId',
  /** column name */
  Price = 'price',
  /** column name */
  VideoFileId = 'videoFileId'
}

/** aggregate stddev on columns */
export type Training_Stddev_Fields = {
  __typename?: 'training_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "training" */
export type Training_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Training_Stddev_Pop_Fields = {
  __typename?: 'training_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "training" */
export type Training_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Training_Stddev_Samp_Fields = {
  __typename?: 'training_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "training" */
export type Training_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "training" */
export type Training_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Training_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Training_Stream_Cursor_Value_Input = {
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  previewFileId?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  videoFileId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Training_Sum_Fields = {
  __typename?: 'training_sum_fields';
  id?: Maybe<Scalars['Int']>;
  previewFileId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  videoFileId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "training" */
export type Training_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Training_Var_Pop_Fields = {
  __typename?: 'training_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "training" */
export type Training_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Training_Var_Samp_Fields = {
  __typename?: 'training_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "training" */
export type Training_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Training_Variance_Fields = {
  __typename?: 'training_variance_fields';
  id?: Maybe<Scalars['Float']>;
  previewFileId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  videoFileId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "training" */
export type Training_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  previewFileId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  videoFileId?: InputMaybe<Order_By>;
};

export type AcceptOrderTestMutationVariables = Exact<{
  id: Scalars['Int'];
  retrievedOn: Scalars['timestamptz'];
}>;


export type AcceptOrderTestMutation = { __typename?: 'mutation_root', update_order_test_by_pk?: { __typename?: 'order_test', id: number } | null };

export type DeleteOrderTestResultMutationVariables = Exact<{
  fileId: Scalars['Int'];
  orderTestId: Scalars['Int'];
}>;


export type DeleteOrderTestResultMutation = { __typename?: 'mutation_root', delete_order_test_result_by_pk?: { __typename?: 'order_test_result', orderTestId: number, fileId: number } | null };

export type GetAcceptedOrdersQueryVariables = Exact<{
  laboratoryId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Order_Order_By> | Order_Order_By>;
}>;


export type GetAcceptedOrdersQuery = { __typename?: 'query_root', order: Array<{ __typename?: 'order', id: number, currentState?: string | null, totalCost: any, orderCollections: Array<{ __typename?: 'order_collection', collectionDate: any, nurseNotes?: string | null, address: string, postalCode: string, city: string, phoneNumber: string }>, profiles: Array<{ __typename?: 'order_test', firstName: string, lastName: string, identity?: any | null }>, documents: Array<{ __typename?: 'order_document', id: number, fileId: number }>, nurse?: { __typename?: 'nurse', name?: string | null } | null, orderStates: Array<{ __typename?: 'order_state', date: any, state: Order_State_Enum_Enum }>, orderTests: Array<{ __typename?: 'order_test', id: number, firstName: string, lastName: string, identity?: any | null, tubeCode?: string | null, laboratoryCode?: string | null, testResultsCount?: any | null, finishedOn?: any | null, retrievedOn?: any | null, collectedOn?: any | null, test: { __typename?: 'test', id: number, name?: string | null, labInstructions?: string | null }, order: { __typename?: 'order', id: number }, collectionPoint?: { __typename?: 'laboratory_collection_point', id: number, laboratoryId: number } | null, testResults: Array<{ __typename?: 'order_test_result', date?: any | null, originalFilename: string, file: { __typename?: 'file', id: number, name: string } }> }> }>, order_aggregate: { __typename?: 'order_aggregate', aggregate?: { __typename?: 'order_aggregate_fields', count: number } | null } };

export type GetAvailableTestsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetAvailableTestsQuery = { __typename?: 'query_root', test: Array<{ __typename?: 'test', id: number, name?: string | null }> };

export type GetFinishedOrdersQueryVariables = Exact<{
  laboratoryId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Order_Order_By> | Order_Order_By>;
}>;


export type GetFinishedOrdersQuery = { __typename?: 'query_root', order: Array<{ __typename?: 'order', id: number, currentState?: string | null, totalCost: any, orderCollections: Array<{ __typename?: 'order_collection', collectionDate: any, nurseNotes?: string | null, address: string, postalCode: string, city: string, phoneNumber: string }>, profiles: Array<{ __typename?: 'order_test', firstName: string, lastName: string, identity?: any | null }>, documents: Array<{ __typename?: 'order_document', id: number, fileId: number }>, nurse?: { __typename?: 'nurse', name?: string | null } | null, orderStates: Array<{ __typename?: 'order_state', date: any, state: Order_State_Enum_Enum }>, orderTests: Array<{ __typename?: 'order_test', id: number, firstName: string, lastName: string, identity?: any | null, tubeCode?: string | null, laboratoryCode?: string | null, testResultsCount?: any | null, finishedOn?: any | null, retrievedOn?: any | null, collectedOn?: any | null, test: { __typename?: 'test', id: number, name?: string | null, labInstructions?: string | null }, order: { __typename?: 'order', id: number }, collectionPoint?: { __typename?: 'laboratory_collection_point', id: number, laboratoryId: number } | null, testResults: Array<{ __typename?: 'order_test_result', date?: any | null, originalFilename: string, file: { __typename?: 'file', id: number, name: string } }> }> }>, order_aggregate: { __typename?: 'order_aggregate', aggregate?: { __typename?: 'order_aggregate_fields', count: number } | null } };

export type GetLabUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLabUserQuery = { __typename?: 'query_root', lab_user: Array<{ __typename?: 'lab_user', id: number, name: string }> };

export type GetLaboratoryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetLaboratoryQuery = { __typename?: 'query_root', laboratory_by_pk?: { __typename?: 'laboratory', id: number, name: string, shortName?: string | null, laboratoryTests: Array<{ __typename?: 'laboratory_test', id: number, laboratoryId: number, testId: number }>, collectionPoints: Array<{ __typename?: 'laboratory_collection_point', address: string, city: string, id: number, location: { type: 'Point'; coordinates: [number, number] }, name: string, postalCode: string }> } | null };

export type GetOrderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetOrderQuery = { __typename?: 'query_root', order_by_pk?: { __typename?: 'order', id: number, currentState?: string | null, totalCost: any, orderCollections: Array<{ __typename?: 'order_collection', collectionDate: any, nurseNotes?: string | null, address: string, postalCode: string, city: string, phoneNumber: string }>, profiles: Array<{ __typename?: 'order_test', firstName: string, lastName: string, identity?: any | null }>, documents: Array<{ __typename?: 'order_document', id: number, fileId: number }>, nurse?: { __typename?: 'nurse', name?: string | null } | null, orderStates: Array<{ __typename?: 'order_state', date: any, state: Order_State_Enum_Enum }>, orderTests: Array<{ __typename?: 'order_test', id: number, firstName: string, lastName: string, identity?: any | null, tubeCode?: string | null, laboratoryCode?: string | null, testResultsCount?: any | null, finishedOn?: any | null, retrievedOn?: any | null, collectedOn?: any | null, test: { __typename?: 'test', id: number, name?: string | null, labInstructions?: string | null }, order: { __typename?: 'order', id: number }, collectionPoint?: { __typename?: 'laboratory_collection_point', id: number, laboratoryId: number } | null, testResults: Array<{ __typename?: 'order_test_result', date?: any | null, originalFilename: string, file: { __typename?: 'file', id: number, name: string } }> }> } | null };

export type GetOrderTestByCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type GetOrderTestByCodeQuery = { __typename?: 'query_root', order_test: Array<{ __typename?: 'order_test', id: number, finishedOn?: any | null, order: { __typename?: 'order', id: number, currentState?: string | null } }> };

export type GetOrderTestByResultFileQueryVariables = Exact<{
  where: Order_Test_Bool_Exp;
}>;


export type GetOrderTestByResultFileQuery = { __typename?: 'query_root', order_test: Array<{ __typename?: 'order_test', id: number, laboratoryCode?: string | null, tubeCode?: string | null, firstName: string, lastName: string, identity?: any | null, test: { __typename?: 'test', name?: string | null }, order: { __typename?: 'order', id: number, currentState?: string | null } }> };

export type GetReportedOrdersQueryVariables = Exact<{
  laboratoryId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Order_Order_By> | Order_Order_By>;
}>;


export type GetReportedOrdersQuery = { __typename?: 'query_root', order: Array<{ __typename?: 'order', id: number, currentState?: string | null, totalCost: any, orderCollections: Array<{ __typename?: 'order_collection', collectionDate: any, nurseNotes?: string | null, address: string, postalCode: string, city: string, phoneNumber: string }>, profiles: Array<{ __typename?: 'order_test', firstName: string, lastName: string, identity?: any | null }>, documents: Array<{ __typename?: 'order_document', id: number, fileId: number }>, nurse?: { __typename?: 'nurse', name?: string | null } | null, orderStates: Array<{ __typename?: 'order_state', date: any, state: Order_State_Enum_Enum }>, orderTests: Array<{ __typename?: 'order_test', id: number, firstName: string, lastName: string, identity?: any | null, tubeCode?: string | null, laboratoryCode?: string | null, testResultsCount?: any | null, finishedOn?: any | null, retrievedOn?: any | null, collectedOn?: any | null, test: { __typename?: 'test', id: number, name?: string | null, labInstructions?: string | null }, order: { __typename?: 'order', id: number }, collectionPoint?: { __typename?: 'laboratory_collection_point', id: number, laboratoryId: number } | null, testResults: Array<{ __typename?: 'order_test_result', date?: any | null, originalFilename: string, file: { __typename?: 'file', id: number, name: string } }> }> }>, order_aggregate: { __typename?: 'order_aggregate', aggregate?: { __typename?: 'order_aggregate_fields', count: number } | null } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'mutation_root', lab_user_logout?: { __typename?: 'LabUserLogoutOutput', result: string } | null };

export type PingMutationVariables = Exact<{ [key: string]: never; }>;


export type PingMutation = { __typename?: 'mutation_root', ping?: { __typename?: 'PingOutput', pong: boolean } | null };

export type SendOrderTestMutationVariables = Exact<{
  id: Scalars['Int'];
  now: Scalars['timestamptz'];
}>;


export type SendOrderTestMutation = { __typename?: 'mutation_root', update_order_test_by_pk?: { __typename?: 'order_test', id: number } | null };

export type UpdateLaboratoryCodeMutationVariables = Exact<{
  orderTestId: Scalars['Int'];
  laboratoryCode?: InputMaybe<Scalars['String']>;
}>;


export type UpdateLaboratoryCodeMutation = { __typename?: 'mutation_root', update_order_test_by_pk?: { __typename?: 'order_test', id: number } | null };

export type UpdateOrderStateMutationVariables = Exact<{
  orderId: Scalars['Int'];
  state: Order_State_Enum_Enum;
}>;


export type UpdateOrderStateMutation = { __typename?: 'mutation_root', insert_order_state_one?: { __typename?: 'order_state', id: number } | null };

export type LaboratoryFragment = { __typename?: 'laboratory', id: number, name: string, shortName?: string | null, laboratoryTests: Array<{ __typename?: 'laboratory_test', id: number, laboratoryId: number, testId: number }>, collectionPoints: Array<{ __typename?: 'laboratory_collection_point', address: string, city: string, id: number, location: { type: 'Point'; coordinates: [number, number] }, name: string, postalCode: string }> };

export type OrderFragment = { __typename?: 'order', id: number, currentState?: string | null, totalCost: any, orderCollections: Array<{ __typename?: 'order_collection', collectionDate: any, nurseNotes?: string | null, address: string, postalCode: string, city: string, phoneNumber: string }>, profiles: Array<{ __typename?: 'order_test', firstName: string, lastName: string, identity?: any | null }>, documents: Array<{ __typename?: 'order_document', id: number, fileId: number }>, nurse?: { __typename?: 'nurse', name?: string | null } | null, orderStates: Array<{ __typename?: 'order_state', date: any, state: Order_State_Enum_Enum }>, orderTests: Array<{ __typename?: 'order_test', id: number, firstName: string, lastName: string, identity?: any | null, tubeCode?: string | null, laboratoryCode?: string | null, testResultsCount?: any | null, finishedOn?: any | null, retrievedOn?: any | null, collectedOn?: any | null, test: { __typename?: 'test', id: number, name?: string | null, labInstructions?: string | null }, order: { __typename?: 'order', id: number }, collectionPoint?: { __typename?: 'laboratory_collection_point', id: number, laboratoryId: number } | null, testResults: Array<{ __typename?: 'order_test_result', date?: any | null, originalFilename: string, file: { __typename?: 'file', id: number, name: string } }> }> };

export const LaboratoryFragmentDoc = gql`
    fragment laboratory on laboratory {
  id
  name
  shortName
  laboratoryTests {
    id
    laboratoryId
    testId
  }
  collectionPoints {
    address
    city
    id
    location
    name
    postalCode
  }
}
    `;
export const OrderFragmentDoc = gql`
    fragment order on order {
  id
  currentState
  totalCost
  orderCollections {
    collectionDate
    nurseNotes
    address
    postalCode
    city
    phoneNumber
  }
  profiles: orderTests(distinct_on: [firstName, lastName, identity]) {
    firstName
    lastName
    identity
  }
  documents {
    id
    fileId
  }
  nurse {
    name
  }
  orderStates(order_by: {date: desc, id: desc}, limit: 1) {
    date
    state
  }
  orderTests(where: {collectionPointId: {_is_null: false}}, order_by: {id: asc}) {
    id
    test {
      id
      name
      labInstructions
    }
    order {
      id
    }
    firstName
    lastName
    identity
    tubeCode
    collectionPoint {
      id
      laboratoryId
    }
    laboratoryCode
    testResultsCount
    testResults {
      date
      originalFilename
      file {
        id
        name
      }
    }
    finishedOn
    retrievedOn
    collectedOn
  }
}
    `;
export const AcceptOrderTestDocument = gql`
    mutation AcceptOrderTest($id: Int!, $retrievedOn: timestamptz!) {
  update_order_test_by_pk(
    pk_columns: {id: $id}
    _set: {retrievedOn: $retrievedOn}
  ) {
    id
  }
}
    `;

export function useAcceptOrderTestMutation() {
  return Urql.useMutation<AcceptOrderTestMutation, AcceptOrderTestMutationVariables>(AcceptOrderTestDocument);
};
export const DeleteOrderTestResultDocument = gql`
    mutation DeleteOrderTestResult($fileId: Int!, $orderTestId: Int!) {
  delete_order_test_result_by_pk(fileId: $fileId, orderTestId: $orderTestId) {
    orderTestId
    fileId
  }
}
    `;

export function useDeleteOrderTestResultMutation() {
  return Urql.useMutation<DeleteOrderTestResultMutation, DeleteOrderTestResultMutationVariables>(DeleteOrderTestResultDocument);
};
export const GetAcceptedOrdersDocument = gql`
    query GetAcceptedOrders($laboratoryId: Int!, $limit: Int = 20, $offset: Int = 0, $orderBy: [order_order_by!] = [{id: asc}]) {
  order(
    where: {_not: {orderTests: {collectionPoint: {laboratoryId: {_eq: $laboratoryId}}, retrievedOn: {_is_null: true}}}, orderTests: {collectionPoint: {laboratoryId: {_eq: $laboratoryId}}, retrievedOn: {_is_null: false}, testResultsCount: {_eq: 0}}}
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...order
  }
  order_aggregate(
    where: {_not: {orderTests: {collectionPoint: {laboratoryId: {_eq: $laboratoryId}}, retrievedOn: {_is_null: true}}}, orderTests: {collectionPoint: {laboratoryId: {_eq: $laboratoryId}}, retrievedOn: {_is_null: false}, testResultsCount: {_eq: 0}}}
  ) {
    aggregate {
      count
    }
  }
}
    ${OrderFragmentDoc}`;

export function useGetAcceptedOrdersQuery(options: Omit<Urql.UseQueryArgs<GetAcceptedOrdersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAcceptedOrdersQuery, GetAcceptedOrdersQueryVariables>({ query: GetAcceptedOrdersDocument, ...options });
};
export const GetAvailableTestsDocument = gql`
    query GetAvailableTests($limit: Int!, $offset: Int = 0) {
  test(where: {deletedAt: {_is_null: true}}, limit: $limit, offset: $offset) {
    id
    name
  }
}
    `;

export function useGetAvailableTestsQuery(options: Omit<Urql.UseQueryArgs<GetAvailableTestsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAvailableTestsQuery, GetAvailableTestsQueryVariables>({ query: GetAvailableTestsDocument, ...options });
};
export const GetFinishedOrdersDocument = gql`
    query GetFinishedOrders($laboratoryId: Int!, $limit: Int = 20, $offset: Int = 0, $orderBy: [order_order_by!] = [{id: asc}]) {
  order(
    where: {_not: {orderTests: {testResultsCount: {_eq: 0}}}, orderTests: {collectionPoint: {laboratoryId: {_eq: $laboratoryId}}}}
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...order
  }
  order_aggregate(
    where: {_not: {orderTests: {testResultsCount: {_eq: 0}}}, orderTests: {collectionPoint: {laboratoryId: {_eq: $laboratoryId}}}}
  ) {
    aggregate {
      count
    }
  }
}
    ${OrderFragmentDoc}`;

export function useGetFinishedOrdersQuery(options: Omit<Urql.UseQueryArgs<GetFinishedOrdersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFinishedOrdersQuery, GetFinishedOrdersQueryVariables>({ query: GetFinishedOrdersDocument, ...options });
};
export const GetLabUserDocument = gql`
    query GetLabUser {
  lab_user {
    id
    name
  }
}
    `;

export function useGetLabUserQuery(options?: Omit<Urql.UseQueryArgs<GetLabUserQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLabUserQuery, GetLabUserQueryVariables>({ query: GetLabUserDocument, ...options });
};
export const GetLaboratoryDocument = gql`
    query GetLaboratory($id: Int!) {
  laboratory_by_pk(id: $id) {
    ...laboratory
  }
}
    ${LaboratoryFragmentDoc}`;

export function useGetLaboratoryQuery(options: Omit<Urql.UseQueryArgs<GetLaboratoryQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLaboratoryQuery, GetLaboratoryQueryVariables>({ query: GetLaboratoryDocument, ...options });
};
export const GetOrderDocument = gql`
    query GetOrder($id: Int!) {
  order_by_pk(id: $id) {
    ...order
  }
}
    ${OrderFragmentDoc}`;

export function useGetOrderQuery(options: Omit<Urql.UseQueryArgs<GetOrderQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrderQuery, GetOrderQueryVariables>({ query: GetOrderDocument, ...options });
};
export const GetOrderTestByCodeDocument = gql`
    query GetOrderTestByCode($code: String!) {
  order_test(where: {tubeCode: {_eq: $code}}) {
    id
    finishedOn
    order {
      id
      currentState
    }
  }
}
    `;

export function useGetOrderTestByCodeQuery(options: Omit<Urql.UseQueryArgs<GetOrderTestByCodeQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrderTestByCodeQuery, GetOrderTestByCodeQueryVariables>({ query: GetOrderTestByCodeDocument, ...options });
};
export const GetOrderTestByResultFileDocument = gql`
    query GetOrderTestByResultFile($where: order_test_bool_exp!) {
  order_test(where: $where) {
    id
    laboratoryCode
    tubeCode
    test {
      name
    }
    firstName
    lastName
    identity
    order {
      id
      currentState
    }
  }
}
    `;

export function useGetOrderTestByResultFileQuery(options: Omit<Urql.UseQueryArgs<GetOrderTestByResultFileQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrderTestByResultFileQuery, GetOrderTestByResultFileQueryVariables>({ query: GetOrderTestByResultFileDocument, ...options });
};
export const GetReportedOrdersDocument = gql`
    query GetReportedOrders($laboratoryId: Int!, $limit: Int = 20, $offset: Int = 0, $orderBy: [order_order_by!] = [{id: asc}]) {
  order(
    where: {orderTests: {collectionPoint: {laboratoryId: {_eq: $laboratoryId}}, retrievedOn: {_is_null: true}}, currentState: {_nin: ["Unpaid", "Cancelled", "AwaitingClientConfirmation"]}}
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...order
  }
  order_aggregate(
    where: {orderTests: {collectionPoint: {laboratoryId: {_eq: $laboratoryId}}, retrievedOn: {_is_null: true}}, currentState: {_nin: ["Unpaid", "Cancelled", "AwaitingClientConfirmation"]}}
  ) {
    aggregate {
      count
    }
  }
}
    ${OrderFragmentDoc}`;

export function useGetReportedOrdersQuery(options: Omit<Urql.UseQueryArgs<GetReportedOrdersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetReportedOrdersQuery, GetReportedOrdersQueryVariables>({ query: GetReportedOrdersDocument, ...options });
};
export const LogoutDocument = gql`
    mutation Logout {
  lab_user_logout {
    result
  }
}
    `;

export function useLogoutMutation() {
  return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument);
};
export const PingDocument = gql`
    mutation Ping {
  ping {
    pong
  }
}
    `;

export function usePingMutation() {
  return Urql.useMutation<PingMutation, PingMutationVariables>(PingDocument);
};
export const SendOrderTestDocument = gql`
    mutation SendOrderTest($id: Int!, $now: timestamptz!) {
  update_order_test_by_pk(pk_columns: {id: $id}, _set: {finishedOn: $now}) {
    id
  }
}
    `;

export function useSendOrderTestMutation() {
  return Urql.useMutation<SendOrderTestMutation, SendOrderTestMutationVariables>(SendOrderTestDocument);
};
export const UpdateLaboratoryCodeDocument = gql`
    mutation UpdateLaboratoryCode($orderTestId: Int!, $laboratoryCode: String) {
  update_order_test_by_pk(
    pk_columns: {id: $orderTestId}
    _set: {laboratoryCode: $laboratoryCode}
  ) {
    id
  }
}
    `;

export function useUpdateLaboratoryCodeMutation() {
  return Urql.useMutation<UpdateLaboratoryCodeMutation, UpdateLaboratoryCodeMutationVariables>(UpdateLaboratoryCodeDocument);
};
export const UpdateOrderStateDocument = gql`
    mutation UpdateOrderState($orderId: Int!, $state: order_state_enum_enum!) {
  insert_order_state_one(object: {orderId: $orderId, state: $state}) {
    id
  }
}
    `;

export function useUpdateOrderStateMutation() {
  return Urql.useMutation<UpdateOrderStateMutation, UpdateOrderStateMutationVariables>(UpdateOrderStateDocument);
};